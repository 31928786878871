.executive-list-main-container {
  background-color: rgb(0, 51, 102);
  padding-bottom: 3rem;
  min-height: 95vh;
}
.executive-list-logo-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  border-bottom: 6px solid #eb1c22;
  padding-bottom: 1.5rem;
}
.executive-list-logo {
  object-fit: cover;
  width: 100%;
  max-width: 300px;
}
.executive-list-logo-top > h2 {
  color: white;
  font-weight: bold;
  font-size: 2.8rem;
  margin-top: -3rem;
  background-color: transparent;
  text-shadow: 5px 3px #eb1c22;
}
.executive-list-fluid {
  margin-top: 3rem;
}
.executive-card-wrapper > a{
  text-decoration: none !important;
  color: #505962;
}
.executive-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.executive-list-container {
  padding: 0;
}
@media screen and (max-width: 600px) {
  .executive-list-logo-top > h2 {
    font-size: 2.2rem;
    margin-top: -1.5rem;
  }
  .executive-list-logo {
    max-width: 180px;
  }
  .executive-list-logo-top {
    border-bottom-width: 4px;
  }
  .executive-list-fluid {
    margin-top: 2rem;
  }
  .executive-list-logo-top {
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .executive-list-logo-top > h2 {
    font-size: 2rem;
  }
  .executive-list-logo {
    max-width: 160px;
  }
}
