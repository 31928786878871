.admin-nav-fluid {
  min-height: 100vh;
}
.admin-nav-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.admin-nav-container > a {
  text-decoration: none;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  border: 2px solid #003366;
  padding: 0.4rem;
}

.admin-nav-container > a:nth-child(1) {
  border-right: 0;
}
.admin-nav-container > a:nth-child(2) {
  border-right: 0;
}
.active-link {
  background-color: #003366;
  color: white !important;
}
