@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');

/* start of a body */
body {
  padding-top: 58px;
  overflow-x: hidden;
  font-family: 'Lato', sans-serif;
  color: #505962;
  background-color: white;
}
/* end of a body */

/* start nav-bar */
.navbar {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9rem;
  background-color: #003366 !important;
  /* background: rgba(19, 2, 114, 0.8) !important; */
}
.navbar-brand {
  margin: 0 !important;
  padding: 0 !important;
}
.navbar-nav li {
  padding-right: 0.6rem;
}
.navbar-dark .navbar-nav .nav-link {
  color: white;
  padding-top: 0.8rem;
}
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link:hover {
  color: red;
}
#nav-bar-logo {
  height: 50px;
  width: 50px;
}
#nynfc-text {
  font-size: 30px !important;
  margin-left: 10px !important;
  font-weight: bold;
  background: -webkit-radial-gradient(circle, blue, white, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* end nav-bar */

/* Start of a Slider */
.parallex-container-main {
  height: 120vh;
  background-color: rgba(0, 76, 153, 1);
  color: white;
  position: fixed;
  z-index: -1;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.parallex-container {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.div-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0.8rem;
}
.rotate-div {
  transform: rotate(45deg);
  height: 30px;
  width: 30px;
  background-color: transparent;
  margin-left: 1rem;
}
.rotate-red {
  border: 1px solid red;
  animation-delay: 0s;
  animation: rotate 5s ease-in forwards infinite;
}
.rotate-yellow {
  border: 1px solid yellow;
  animation-delay: 2s;
  animation: rotate 5s ease-in forwards infinite;
}
.rotate-blue {
  border: 1px solid green;
  animation-delay: 3s;
  animation: rotate 5s ease-in forwards infinite;
}
@keyframes rotate {
  from {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: scale(1) rotate(360deg);
  }
  to {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}
.nynfc-parallex-img {
  width: 500px;
  height: 500px;
  transform: scale(1.1);
  animation: scaleUp 1s ease-in forwards;
  animation-iteration-count: 1;
}
@keyframes scaleUp {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.parallex-container h1 {
  font-weight: bold;
  font-size: 4rem;
  text-shadow: 2px 3px #eb1c22;
  margin-top: -5rem;
  animation: rotateH1 3s ease-in forwards;
  animation-iteration-count: 1;
}
@keyframes rotateH1 {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.parallex-container h3 {
  font-size: 1.8rem;
  text-shadow: 1px 1px yellow;
  animation: scaleH3 4s ease-in forwards;
  animation-iteration-count: 1;
}

@keyframes scaleH3 {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.hero__title {
  color: #fff;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -55%);
  font-size: 50px;
  z-index: 1;
}

.hero {
  background-color: #0040c1;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.cube {
  position: absolute;
  top: 85vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px #003298;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: cube 5s ease-in forwards infinite;
}
.cube:nth-child(2n) {
  border-color: #0051f4;
}
.cube:nth-child(2) {
  animation-delay: 1s;
  left: 25vw;
  top: 40vh;
}
.cube:nth-child(3) {
  animation-delay: 2s;
  left: 75vw;
  top: 50vh;
}
.cube:nth-child(4) {
  animation-delay: 3s;
  left: 90vw;
  top: 10vh;
}
.cube:nth-child(5) {
  animation-delay: 4s;
  left: 10vw;
  top: 85vh;
}
.cube:nth-child(6) {
  animation-delay: 5s;
  left: 50vw;
  top: 10vh;
}
@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

/* end of a Slider */

/* start of about us */
.img-size {
  height: auto;
  width: 100%;
  margin-top: 30px;
}
.about {
  font-size: 8rem;
  text-align: center;
  text-transform: uppercase;
  line-height: 0px;
  color: black;
}

.about-us {
  font-size: 3rem;
  line-height: 0px;
  color: #eb1c22;
}

/* end of about us */

/* start of sponser */
.sponser-bg {
  background-color: rgba(0, 51, 102, 0.9);
  color: white;
}
#lama-acc-heading {
  font-family: 'Cinzel Decorative', cursive;
  font-weight: bold;
  font-size: 2rem;
  color: white;
}
#mustang-thakali-heading {
  font-family: 'Zhi Mang Xing', cursive;
  font-size: 2.5rem;
  color: #c6805e;
}

#lama-acc-heading:hover {
  opacity: 0.5;
  cursor: pointer;
}
#mustang-thakali-heading:hover {
  opacity: 0.5;
  cursor: pointer;
}

/* end of sponser */

/* start of Register Form  */
.register-container-main {
  background-color: #003366;
  /* padding: 4rem; */
  height: 91vh;
}
.register-heading {
  font-weight: bold;
  color: white;
}
.form-p {
  color: grey;
}
/* .register-form {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.nynfc-form {
  width: 100%;
  max-width: 600px;
  margin: auto;
}
.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='number'],
.form select,
.form input[type='date'] {
  display: block;
  outline: none;
  width: 100%;
  padding: 0.4rem;
  font-size: 1rem;
  background-color: transparent;
  color: white !important;
  border: none;
  border-bottom: 1px solid white;
  margin-bottom: 0;
}
.nynfc-form input[type='submit'] {
  max-width: 300px;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
/* end of Register Form  */

/* start of player-profile */

.player-profile-row {
  background-color: #003366;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  border-bottom: 4px solid #eb1c22;
}
.nynfc-profile-img {
  height: 300px;
}

.player-profile-row h1 {
  font-weight: bold;
  font-size: 4rem;
  position: relative;
  top: -2rem;
}
.social-media {
  position: relative;
  top: -2rem;
  font-size: 1.5rem;
  margin-bottom: 4.1rem;
}

.player-left-col {
  background-color: whitesmoke;
}
.player-right-col {
  background-color: whitesmoke;
}
.player-profile-img {
  height: 192px;
  width: 192px;
  object-fit: cover;
  border-radius: 100%;
  border: 4px solid #eb1c22;
  margin-top: -6.32rem;
}
.player-information-h1 {
  font-weight: bold;
  color: black;
  padding: 1rem;
  border-bottom: 4px solid #003366;
}
.center-player-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}
.player-info-container {
  /* border-top: 10px solid #eb1c22;
  border-bottom: 10px solid #eb1c22; */
  background-color: whitesmoke;
}
.player-info-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}
.player-info-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.player-info-col-left {
  width: 400px;
  padding: 1rem;
}
.player-info-col-right {
  padding: 1rem;
}
.player-info-col-left h5 {
  font-weight: bold;
  padding: 1px;
}
.player-info-col-right h5 {
  padding: 1px;
}
.player-info-col-right {
  padding: 1rem;
}

.paid {
  color: green;
}
.unpaid{
  transform: scale(1.5);
  color:#eb1c22;
}

/* end of player-profile */

/* start of players Landing page */
.players-landing-container-main {
  padding: 0 !important;
  background-color: #003366;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
}
.players-landing-img-h1 {
  background-color: #003366;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  border-bottom: 6px solid #eb1c22;
  position: relative;
  margin-bottom: 7rem;
}
.player-landing-h1 {
  position: absolute;
  color: white;
  font-weight: bold;
  font-size: 4rem;
  padding-bottom: 3.5rem;
  bottom: 7rem;
  background-color: transparent;
  text-shadow: 2px 3px #eb1c22;
}
.players-landing-img {
  height: 500px;
  width: 500px;
  -webkit-filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.7));
}
.players-landing-nynfc-img {
  height: 200px;
  width: 200px;
  position: absolute;
  bottom: -6.5rem;
}
.players-landing-container {
  /* padding: 3rem; */
  padding-bottom: 5rem;
  margin-bottom: 2rem;
}
.players-landing-h1s {
  background-color: #003366;
  font-weight: bold;
  font-size: 2.5rem;
  padding: 0.5rem;
  color: white;
  padding-bottom: 1rem;
  /* border:3px solid white; */
  border-bottom: 3px solid #eb1c22;
}
/* end of players Landing page */

/* start of playerCarousel */

.item {
  margin: 1px !important;
}
.carousel-card {
  width: 280px;
  border: 1px solid red;
  background-color: whitesmoke;
  color: black;
  box-shadow: 0 0 30px black;
  margin: 10px !important;
  border-radius: 5%;
}
.carousel-card h3 {
  font-weight: bold;
}
.carousel-card h1 {
  font-weight: bold;
  font-size: 4rem;
  color: #eb1c22;
  line-height: 0.5;
  padding-bottom: 10px;
}
.carousel-img {
  height: 300px;
  background-size: contain;
  border-top-left-radius: 5%;
  border-top-right-radius: 5%;
}

/* end of playerCarousel */

/* Start of media  queries */

@media (max-width: 500px) {
  .nynfc-parallex-img {
    height: 300px;
    width: 300px;
  }
  .parallex-container h1 {
    font-size: 2.6rem;
    margin-top: 0;
  }
  .parallex-container h3 {
    font-size: 1.4rem;
  }
  .about-us-desc {
    margin-top: -3.4rem;
  }
  #about-us p {
    text-align: justify !important;
    padding: 0 0.6rem;
  }
  .p-5 {
    padding: 0.1rem !important;
  }
  #lama-acc-heading {
    font-size: 1rem !important;
  }
  #mustang-thakali-heading {
    font-size: 2rem !important;
  }
}
@media only screen and (max-width: 600px) {
  .center-player-info {
    margin-top: 1rem;
  }
  .carousel-item {
    height: 100vh;
  }
  .about {
    font-size: 6rem;
  }
  .about-us {
    font-size: 2rem;
  }
  #trophies-heading::before {
    width: 18rem;
  }
  #executive-heading::before {
    width: 20rem;
  }
  #lama-acc-heading {
    font-size: 2.5rem;
  }
  .timeline h1 {
    font-size: 1.2rem;
  }

  .timeline .date::before {
    font-size: 0.8rem;
  }
  .timeline ul {
    padding: 5rem 3rem 5rem 3rem;
  }
  .timeline .date::before {
    left: -6rem;
  }
  .timeline .date::after {
    left: -3.2rem;
  }
  #medal-img {
    left: -10%;
  }

  .player-info-col-left {
    width: auto;
  }
  .nynfc-profile-img {
    height: 150px;
  }
  .player-profile-row h1 {
    font-size: 2rem;
    padding-top: 0.5rem;
  }
  .social-media {
    font-size: 1.2rem;
  }
  .player-information-h1 {
    font-size: 1.8rem;
    padding: 4px;
  }
  .player-info-col-left h5 {
    font-size: 1rem;
  }
  .player-info-col-right h5 {
    font-size: 1rem;
  }
  .player-info-container {
    margin-bottom: 3rem;
  }
  .player-profile-img {
    height: 150px;
    width: 150px;
  }
  .player-profile-img {
    margin-top: -5.32rem;
  }
  .google-maps {
    display: none;
  }
  .players-landing-container {
    padding: 1rem;
  }
  .players-landing-img {
    width: 250px;
    height: 250px;
    margin-bottom: 1rem;
  }
  .player-landing-h1 {
    font-size: 2rem;
    bottom: 3rem;
  }
  .players-landing-nynfc-img {
    width: 150px;
    height: 150px;
    bottom: -5rem;
  }
  .players-landing-img-h1 {
    margin-bottom: 4rem;
  }
  .carousel-card {
    margin-left: 2rem !important;
  }
}

/* ipad */
@media (max-width: 800px) {
  .about {
    font-size: 4rem;
    margin-top: 3rem;
  }
  .about-us {
    font-size: 1.5rem;
    line-height: 0;
    margin-top: -0.2rem;
  }
  #about-us p {
    margin-top: -2.5rem;
  }
  #lama-acc-heading {
    font-size: 2.5rem;
  }
  #mustang-thakali-heading {
    font-size: 2.4rem;
  }
}
/* ipad  portrait*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .news-img {
    width: 150px;
    height: 150px;
  }
}
/* end of media  queries*/
