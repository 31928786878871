.news-container-main{
    /* background-color: white; */
    background-color:rgba(0,51,102,0.8);
    padding:15px;
    text-transform: uppercase;
  }
  #news-heading{
    font-weight: bold;
    color:white;
    position: relative;
    margin-top: 1rem;
  }
  #news-heading::before,
  #news-heading::after{
    content:'';
    position: absolute;
    bottom:-1rem;
    left:50%;
    transform: translate(-50%);
    background-color: #EB1C22;
  }
  #news-heading::before{
    width:15rem;
    height: 3px;
  }
  #news-heading::after{
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
    bottom: -1.5rem;
  }
  .news-container{
    /* margin-top:2rem;
    display: flex;
    justify-content: center;
    align-items: stretch; */
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 15px;
    /* align-items: center; */
    padding: 3rem 0;
  }
  .news-img{
    width: 100%;
    object-fit: cover;
    max-width: 180px;
    height: 150px;
  }
  .news-inner{
    overflow: hidden;
    border:1px solid #EB1C22;
    /* margin-right: .5rem; */
    border-radius: 3%;
    /* box-shadow: 0 0 30px black;  */
    transition: all .3s ease-in;
  }
  .news-inner:hover{
    opacity: .8;
    transform: scale(1.1);
  }
  .news-col-left{  
    min-height: 300px;
    height: 100%;
    /* background-color: white; */
    /* padding: 6px 6px 6px 6px; */
    margin-bottom: 1.3rem;
    box-shadow: 0 0 30px black; 
  }
  .news-col-right{
    width: 100%;
    box-shadow: 0 0 30px black; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .news-col-right-grid{
    display: grid;
    grid-template-columns: repeat(2,150px);
    max-width: 330px;
    width: 100%;
    min-height: 300px;
    height: 100%;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 1.5rem; */
    /* border:6px solid white; */
    column-gap: 10px;
    row-gap: 10px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    
  }
  .news-frame{
      height: 100%;
      width: 100%;
  }

  @media screen and (max-width:1000px){
      .news-container{
          margin-top: 2rem;
          max-width: 660px;
          grid-template-columns: 1fr;
      }
      .news-col-right-grid{
          grid-template-columns: repeat(4, 120px);
      }
      .news-img{
          height: 120px;
      }
  }

  @media screen and (max-width:770px){
      #news-heading{
          font-size: 2.5rem;
      }
  }

  @media screen and (max-width:660px){
    .news-col-right-grid{
      max-width: 270px;
        grid-template-columns: repeat(2, 120px);
    }
  }

  @media screen and (max-width:500px){
    #news-heading{
        font-size: 2rem;
    }
      .news-container{
          margin-top: 3rem;
          padding: 0 !important;
      }
      .news-inner{
          border: none;
      }
      .news-img{
          width: 100px;
          height: 100px;
      }
      .news-col-right-grid{
          margin-bottom: none;
      }
      .news-col-left{
        min-height: 250px;
        max-height: 250px;
      }
  }