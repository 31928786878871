.trophy-history-container-main {
  background-color: rgba(0, 51, 102, 0.9);
  text-align: center;
  padding-left: 12rem !important;
  position: relative;
  overflow: hidden;
}
.trophy-history-bg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.trophy-history-container {
  background-color: transparent;
  color: white;
  position: relative;
}
#trophy-history-heading {
  color: white;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 1rem;
  font-weight: bold;
}

#trophy-history-heading::before,
#trophy-history-heading::after {
  content: '';
  position: absolute;
  bottom: -15rem;
  left: 50%;
  transform: translate(-50%);
  background-color: #eb1c22;
}
#trophy-history-heading::before {
  width: 15rem;
  height: 3px;
}
#trophy-history-heading:after {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  bottom: -15.6rem;
}
.timeline ul {
  border-left: 4px solid #eb1c22;
  border-radius: 0.8rem;
  position: relative;
  padding: 6rem 4rem 6rem 4rem;
  list-style: none;
  text-align: left !important;
}

.timeline h1 {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 1rem;
  opacity: 1rem;
  display: inline;
}

.fa-trophy {
  color: #fcb434 !important;
  font-size: 1.8rem;
  padding-right: 10px;
  margin-bottom: 17px;
}
.fa-trophy-silver {
  color: silver !important;
}
.fa-trophy-bronze {
  color: #b08d57;
}
.timeline .date {
  margin-bottom: 1rem;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  position: relative;
}

.timeline .date:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
.timeline .date::before,
.timeline .date::after {
  position: absolute;
  display: block;
  top: 20%;
  transform: translate(-50%);
}

.timeline .date::before {
  content: attr(date-date);
  left: -7rem;
  text-align: right !important;
  font-size: 1rem;
  /* top: 7px; */
  transform: translate(-50%, -20%);
}

.timeline .date::after {
  content: '';
  box-shadow: 0 0 0 4px white;
  border-radius: 100%;
  left: -4.2rem;
  height: 1rem;
  width: 1rem;
  background-color: #eb1c22;
}

#medal-img {
  width: 300px;
  height: 200px;
  margin-bottom: 4rem;
  position: relative;
}

@media screen and (max-width: 768px) {
  .timeline .date::before {
    left: -7rem;
  }
}

@media screen and (max-width: 768px) {
  .timeline h1 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 540px) {
  .trophy-history-container-main {
    padding-left: 8rem !important;
  }
}

@media screen and (max-width: 450px) {
  .timeline h1 {
    font-size: 1.2rem;
  }
  .timeline ul {
    padding: 6rem 2rem 6rem 2rem;
  }
  .timeline .date::after {
    left: -2.2rem;
  }
  .timeline .date::before {
    left: -5rem;
  }
  .trophy-history-container-main {
    padding-left: 6rem !important;
  }
}

@media screen and (max-width: 432px) {
  .trophy-history-container-main {
    padding-left: 4rem !important;
    overflow: hidden;
  }
  .timeline .date::after {
    height: 0.8rem;
    width: 0.8rem;
  }
  .fa-trophy,
  .fa-trophy-bronze,
  .fa-trophy-silver {
    font-size: 1.3rem;
  }
  .timeline ul {
    padding: 6rem 1.5rem 6rem 1rem;
  }
  .timeline .date::after {
    left: -1.1rem;
  }
  .timeline .date::before {
    left: -3.5rem;
  }
}
