.executive-upload-container {
  margin-top: 50px;
  min-height: 95vh;
}
.executive-upload-heading-bg {
  background-image: url('../images/nynfc_logo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 150px;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.executive-upload-form {
  margin: auto;
  min-width: 500px;
}

.executive-upload-form label {
  text-align: start !important;
}
.executive-upload-heading {
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 0 0.2rem;
  border: 3px solid #003366;
}
.executive-upload-form input[type='text'],
.executive-upload-form input[type='number'],
.executive-upload-form input[type='email'],
.executive-upload-form select,
.executive-upload-form textarea,
.executive-upload-form input[type='file'] {
  display: block;
  outline: none;
  width: 100%;
  padding: 0.2rem;
  font-size: 1rem;
  background-color: transparent;
  color: black !important;
  border: none;
  border-bottom: 1px solid #003366;
  border-radius: 0;
}
.executive-upload-form input[type='text']:focus,
.executive-upload-form input[type='number']:focus,
.executive-upload-form input[type='email']:focus,
.executive-upload-form select:focus,
.executive-upload-form textarea:focus,
.executive-upload-form input[type='file']:focus {
  -webkit-appearance: none;
  /* outline: none; */
}

.executive-upload-button {
  background-color: #003366;
  transition: 0.3s all ease-in;
  border-radius: 0;
}
.executive-upload-button:hover {
  background-color: #003366;
  opacity: 0.8;
}
.center-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* @media screen and (max-width: 500px) {
  .form-group {
    min-width: 400px;
  }
}
@media screen and (max-width: 400px) {
  .form-group {
    width: 350px;
  }
} */

@media screen and (max-width: 550px) {
  .executive-upload-form {
    min-width: 100px;
  }
}
