
.training-session-container-main{
    color:white;
    background-color:rgba(0,51,102,0.9);
  }
  .training-session-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .training-session-innerdiv{
    opacity: .7;
  }
  #training-session-heading{
    font-weight: bold;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    position: relative;
    margin-top: 1rem;
    text-transform: uppercase;
  }
  /* #training-session-heading::after{
    content:"";
    width: 25rem;
    height: 2px;
    background-color: #EB1C22;
    position:absolute;
    top: 4rem;
    left: 50%;
    transform: translate(-50%);
  } */
  .training-session-wrapper{
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EB1C22;
    margin-bottom: 1rem;
  }
  .training-session-wrapper  h6{
    color:#EB1C22;
  }

  .training-session-wrapper h2 {
    text-transform: uppercase;
  }
.training-session-info{
  border: 1px solid white;
}

.training-session-info:nth-child(1){
  margin-right: 2rem;
}
  .training-session-innerdiv{
    line-height: 0.6;
  }

  @media screen and (max-width:770px){
    .training-session-wrapper h2 {
      font-size: 1.8rem;
    }
    .training-session-wrapper h5{
      font-size: 1.1rem;
    }
    .training-session-info:nth-child(1){
      margin-right: 1rem;
    }
  }

  @media screen and (max-width:625px){
    .training-session-info:nth-child(1){
      margin-right: .5rem;
    }
    .training-session-wrapper h2 {
      font-size: 1.4rem;
    }
    .training-session-wrapper h5{
      font-size: 1rem;
    }
  }


  @media screen and (max-width:600px){
      #training-session-heading{
        font-size: 2rem;
      }
      #training-session-heading::after{
        width: 20rem;
      }
  }
@media screen and (max-width:530px){
  .training-session-wrapper{
    flex-direction: column;
  }

  .training-session-info:nth-child(1){
    margin-right: 0;
    margin-bottom: 2rem;
  }
}