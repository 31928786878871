.about-fluid{
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    }
    
    .about-container{
        display: grid;
        grid-template-columns: .35fr .65fr;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding-left: .3rem;
        padding-right: .3rem;
    }
    
    
    .about-nynfc-logo{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .about-nynfc-img{
        width:100%;
        max-width: 300px;
        object-fit: cover;
        height: auto;
    }
    
    .about-info{
        width: 100%;
    }
    .about-info-heading{
        text-align: center;
        color: black;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    
    .about-info-heading > h1{
        font-size: 6rem;
        color:black;
    }
    
    .txt-color {
        color: #EB1C22;
        font-size: 3rem;
        margin-left: -1.8rem;
    }
    
    
    .about-desc{
        display: flex;
        flex-direction:column;
        margin-top: 1rem;
    }
    
    
    .about-info-button{
        margin: auto;
        border: 1px solid #EB1C22;
        font-weight: bold;
        padding: 8px 16px;
        cursor: pointer;
        color: black;
        transition: all .3s ease-in;
        margin-top: 1rem;
        border-radius: 5px;
    }
    
    .about-info-button:hover{
        background-color: #EB1C22;
        color: white;
    }
    
    @media screen and (max-width:1008px){
        .about-container{
            grid-template-columns: 1fr;
        }
    }
    
    @media screen and (max-width:500px){
        .about-nynfc-img{
            max-width: 250px;
        }
            .about-info-heading > h1{
            font-size: 4rem;
            }
    
        .txt-color{
            font-size: 2rem;
            margin-left: -1.3rem;
        }
    }