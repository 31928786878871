#scroll-mouse-btn{
    width: 35px;
    height: 60px;
    border: 2px solid #EB1C22;
    border-radius: 20px;
    position: relative;
}

#scroll-mouse-btn::before{
    content:'';
    position: absolute;
    width:5px;
    height:5px;
    border-radius: 50%;
    background-color: #333;
    left: 50%;
    transform: translate(-50%);
    top: 10px;
    animation: scrollCircle 2s infinite ease-out;
}

@keyframes scrollCircle {
    0% {
        opacity: 0;
        top: 10px;
    }
    20%{
     opacity: .9;
    }
    50%{
        opacity: .5;
    }
    100%{
        opacity: 0;
        background-color: #EB1C22;
        top: 40px;
    }
}
