.image-container{
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height:100vh;
    background-image: url('nynfc_bg2.jpg');
  
    /* remove to put it scroll */
    background-attachment: scroll;
    overflow: hidden;
    animation: changeOpacity 2s forwards;
    /* opacity: 0.1; */
  }
  @keyframes changeOpacity {
      0%{
        right: 50%;
        left: 50%;
      }
      100%{
          opacity: 1;
      }
  }

  .opacity {
      height: 100vh;
      position: relative;
      width: 100%;
      background: rgba(0,51,102,.2);
      /* background: rgba(0,0,0,.3); */
      animation: changeBackground 3s forwards;
  }

  @keyframes changeBackground{
      0%{
        background: rgba(0,0,0,0);
      }
      100%{

      }
  }
 
  .nynfc-h1{
    position: absolute;
    top:60%;
    left:50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    background-image: linear-gradient(to right,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
     );
    padding: .3rem 5rem;
    border-top:3px solid red;
    border-bottom: 3px solid red;
    box-shadow: 5px 10px 8px 10px #8d8b8b;
    animation: drop 2s forwards;
  }
  @keyframes drop {
      0%{
        top:-60%;
        left:50%;
        transform: rotate(180deg);
        background-image: none;
      }
      100%{
        top:60%;
        left:50%;
      }
  }
  .white-border{
      border-top: 3px solid white;
      border-bottom: 3px solid white;
      padding: .3rem 5rem;
  }
  .blue-border{
      border-top: 3px solid blue;
      border-bottom: 3px solid blue;
      padding: .3rem 0;
  }

  .blue-border p {
      margin-top: .5rem;
      margin-bottom: -1.3rem;
      font-size: 2rem;
      font-family: 'Satisfy', cursive;
      color:gold;
  }
  .nynfc-h1 h1{
    font-family: 'Source Serif Pro', serif;
      font-size: 5rem;
      margin-bottom: -.5rem;
  }

  @keyframes borderChange {

  }

  @media (max-width:500px){
      .nynfc-h1{
          padding: .1rem, 1rem;
      }
      .white-border{
        padding: .3rem 1rem;
    }
    .nynfc-h1 h1{
          font-size: 3rem;
    }
    .blue-border{
        padding-top: 0;
    }
    .blue-border p{
        font-size: 1.5rem;
        margin-bottom: -1rem;
    }
  }