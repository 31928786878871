
.full-gallery-main{
    background: #f1f1f1;
    max-width: 1100px;
    width: 100%;
    margin: auto;
  }
  .full-gallery-container{
    padding-top: 2rem;
  }
  .full-gallery-main h1{
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #333;
  }

  .gallery-section{
    max-width: 1100px;
    width: 100%;
    padding: 10px;
    background: #f1f1f1;
    margin: auto;
  }

  .inner-width {
    width: 100%;
    margin: auto;
    padding:0;
  }

.border{
  width: 80%;
  height: 4px;
  background: #333;
  margin: 60px auto;
}


.produce-gallery-img-wrapper{
    max-width: 1100px;
    width:100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 10px;
    row-gap: 10px;
}

.produce-gallery-img-div{
    overflow: hidden;
}

.produce-gallery-img{
    min-height: 250px;
    max-height: 250px;
    width: 100%;
    object-fit: cover;
    transition: all .3s ease-in;
    cursor: pointer;
}


.produce-gallery-img:hover{
  transform: scale(1.2) rotate(5deg);
}


/* modal */
.gallery-section h1{
  text-align: center;
  text-transform: uppercase;
  color:#333;
}
.border{
  width: 300px;
  height: 4px;
  background: #333;
  margin: 20px auto;
}

.modal{
  display: none;
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background: rgba(0,0,0,0.9);
  z-index: 1;
  width: 100%;
  height: 100%;
}
.modal .modal-img-container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img-fluid-custom{
  max-height: 90vh;
  max-width: 98vw;
  object-fit: contain;
}
.button-left{
  position: absolute;
  top: 50%;
  left: -5vw;
  background-color: transparent;
  color: white;
  z-index: 1;
}
.button-right{
  position: absolute;
  top: 50%;
  right: -5vw;
  background-color: transparent;
  color: white;
  z-index: 1;
}
.button-top{
  position: absolute;
  top: -5vh;
  right:-5vw;
  background-color: transparent;
  color: white;
  z-index: 1;
} 

/* end modal */



@media screen and (max-width:880px){
    .produce-gallery-img{
        min-height: 200px;
        max-height: 200px;
    }
}
@media screen and (max-width:770px){
    .produce-gallery-img{
        min-height: 180px;
        max-height: 180px;
    }
}

@media screen and (max-width:650px){
    .produce-gallery-img{
        min-height: 150px;
        max-height: 150px;
    }
}

@media screen and (max-width:600px){
    .produce-gallery-img{
        min-height: 130px;
        max-height: 130px;
    }

}

@media screen and (max-width:500px){
    .produce-gallery-img-wrapper{
        grid-template-columns: repeat(2,1fr);
    }
}
