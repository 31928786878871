@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

.bg-whitesmoke{
    background-color: #f4f4f4;
}
.mainview-fluid{
    height: 100vh;
    background: #003366;
    background-image: url('./images/nynfc_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 0;
    border-bottom-right-radius: 40%;
    /* border-top-left-radius: 30%; */
}

.mainview-fluid::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    border-bottom-right-radius: 40%;
    /* border-top-left-radius: 30%; */
}

.mainview-container{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainview-card{
    background-image: url('./images/nynfc_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 90%;
    height: 500px;
    border: 2px solid white;
    color:white;
    border-top-left-radius: 80px;
    border-bottom-right-radius: 80px;
    box-shadow: 1px 2px 60px rgba(0,0,0,.8);
    position: relative;
    overflow: hidden;
    z-index: 15;
}

.mainview-card-inner{
    background: rgba(0,0,0,.6);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.mainview-card-inner > h1{
    /* background:rgba(0,0,0,.5); */
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 6rem;
}
.mainview-button{
    background:#EB1C22;
    border: 1px solid white;
    padding: .5rem .8rem;
    cursor: pointer;
    border-radius: 5px;
    transition: all .3s ease-in;
    margin-bottom: 1.8rem;
}
.mainview-button:hover{
    opacity: .8;
}

.mainview-card-inner > p {
    font-family: 'Dancing Script', cursive;
    font-size: 2rem;
    margin-bottom: -1.8rem;
    /* color:rgba(220, 53, 69, 1) */
}
.arrow{
    margin-top: 8px;
}
.arrow span{
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 5px solid #EB1C22;
    border-right: 5px solid #EB1C22;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}
.arrow span:nth-child(2){
    animation-delay: -0.2s;
}
@keyframes animate {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}
@media screen and (max-width:1008px){
    .mainview-fluid{
        border-bottom-right-radius: 30%;
    }
    .mainview-fluid::before{
        border-bottom-right-radius: 30%;
    }
}


@media screen and (max-width:600px){
    .mainview-card-inner > h1{ 
        font-size: 4rem;
    }
    .mainview-card{
        height: 300px;
    }
}
