@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
/* start of a body */
body {
  padding-top: 58px;
  overflow-x: hidden;
  font-family: 'Lato', sans-serif;
  color: #505962;
  background-color: white;
}
/* end of a body */

/* start nav-bar */
.navbar {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9rem;
  background-color: #003366 !important;
  /* background: rgba(19, 2, 114, 0.8) !important; */
}
.navbar-brand {
  margin: 0 !important;
  padding: 0 !important;
}
.navbar-nav li {
  padding-right: 0.6rem;
}
.navbar-dark .navbar-nav .nav-link {
  color: white;
  padding-top: 0.8rem;
}
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link:hover {
  color: red;
}
#nav-bar-logo {
  height: 50px;
  width: 50px;
}
#nynfc-text {
  font-size: 30px !important;
  margin-left: 10px !important;
  font-weight: bold;
  background: -webkit-radial-gradient(circle, blue, white, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* end nav-bar */

/* Start of a Slider */
.parallex-container-main {
  height: 120vh;
  background-color: rgba(0, 76, 153, 1);
  color: white;
  position: fixed;
  z-index: -1;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.parallex-container {
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.div-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 0.8rem;
}
.rotate-div {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  height: 30px;
  width: 30px;
  background-color: transparent;
  margin-left: 1rem;
}
.rotate-red {
  border: 1px solid red;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation: rotate 5s ease-in forwards infinite;
          animation: rotate 5s ease-in forwards infinite;
}
.rotate-yellow {
  border: 1px solid yellow;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation: rotate 5s ease-in forwards infinite;
          animation: rotate 5s ease-in forwards infinite;
}
.rotate-blue {
  border: 1px solid green;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  -webkit-animation: rotate 5s ease-in forwards infinite;
          animation: rotate 5s ease-in forwards infinite;
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
  }
  to {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}
@keyframes rotate {
  from {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1) rotate(360deg);
            transform: scale(1) rotate(360deg);
  }
  to {
    -webkit-transform: scale(1) rotate(0deg);
            transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}
.nynfc-parallex-img {
  width: 500px;
  height: 500px;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-animation: scaleUp 1s ease-in forwards;
          animation: scaleUp 1s ease-in forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
@-webkit-keyframes scaleUp {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scaleUp {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.parallex-container h1 {
  font-weight: bold;
  font-size: 4rem;
  text-shadow: 2px 3px #eb1c22;
  margin-top: -5rem;
  -webkit-animation: rotateH1 3s ease-in forwards;
          animation: rotateH1 3s ease-in forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
@-webkit-keyframes rotateH1 {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes rotateH1 {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
.parallex-container h3 {
  font-size: 1.8rem;
  text-shadow: 1px 1px yellow;
  -webkit-animation: scaleH3 4s ease-in forwards;
          animation: scaleH3 4s ease-in forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

@-webkit-keyframes scaleH3 {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleH3 {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.hero__title {
  color: #fff;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -55%);
          transform: translate(-50%, -55%);
  font-size: 50px;
  z-index: 1;
}

.hero {
  background-color: #0040c1;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.cube {
  position: absolute;
  top: 85vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px #003298;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: scale(0) rotate(0deg) translate(-50%, -50%);
          transform: scale(0) rotate(0deg) translate(-50%, -50%);
  -webkit-animation: cube 5s ease-in forwards infinite;
          animation: cube 5s ease-in forwards infinite;
}
.cube:nth-child(2n) {
  border-color: #0051f4;
}
.cube:nth-child(2) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  left: 25vw;
  top: 40vh;
}
.cube:nth-child(3) {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  left: 75vw;
  top: 50vh;
}
.cube:nth-child(4) {
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  left: 90vw;
  top: 10vh;
}
.cube:nth-child(5) {
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
  left: 10vw;
  top: 85vh;
}
.cube:nth-child(6) {
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
  left: 50vw;
  top: 10vh;
}
@-webkit-keyframes cube {
  from {
    -webkit-transform: scale(0) rotate(0deg) translate(-50%, -50%);
            transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(20) rotate(960deg) translate(-50%, -50%);
            transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}
@keyframes cube {
  from {
    -webkit-transform: scale(0) rotate(0deg) translate(-50%, -50%);
            transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(20) rotate(960deg) translate(-50%, -50%);
            transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

/* end of a Slider */

/* start of about us */
.img-size {
  height: auto;
  width: 100%;
  margin-top: 30px;
}
.about {
  font-size: 8rem;
  text-align: center;
  text-transform: uppercase;
  line-height: 0px;
  color: black;
}

.about-us {
  font-size: 3rem;
  line-height: 0px;
  color: #eb1c22;
}

/* end of about us */

/* start of sponser */
.sponser-bg {
  background-color: rgba(0, 51, 102, 0.9);
  color: white;
}
#lama-acc-heading {
  font-family: 'Cinzel Decorative', cursive;
  font-weight: bold;
  font-size: 2rem;
  color: white;
}
#mustang-thakali-heading {
  font-family: 'Zhi Mang Xing', cursive;
  font-size: 2.5rem;
  color: #c6805e;
}

#lama-acc-heading:hover {
  opacity: 0.5;
  cursor: pointer;
}
#mustang-thakali-heading:hover {
  opacity: 0.5;
  cursor: pointer;
}

/* end of sponser */

/* start of Register Form  */
.register-container-main {
  background-color: #003366;
  /* padding: 4rem; */
  height: 91vh;
}
.register-heading {
  font-weight: bold;
  color: white;
}
.form-p {
  color: grey;
}
/* .register-form {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.nynfc-form {
  width: 100%;
  max-width: 600px;
  margin: auto;
}
.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='number'],
.form select,
.form input[type='date'] {
  display: block;
  outline: none;
  width: 100%;
  padding: 0.4rem;
  font-size: 1rem;
  background-color: transparent;
  color: white !important;
  border: none;
  border-bottom: 1px solid white;
  margin-bottom: 0;
}
.nynfc-form input[type='submit'] {
  max-width: 300px;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
/* end of Register Form  */

/* start of player-profile */

.player-profile-row {
  background-color: #003366;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: white;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  border-bottom: 4px solid #eb1c22;
}
.nynfc-profile-img {
  height: 300px;
}

.player-profile-row h1 {
  font-weight: bold;
  font-size: 4rem;
  position: relative;
  top: -2rem;
}
.social-media {
  position: relative;
  top: -2rem;
  font-size: 1.5rem;
  margin-bottom: 4.1rem;
}

.player-left-col {
  background-color: whitesmoke;
}
.player-right-col {
  background-color: whitesmoke;
}
.player-profile-img {
  height: 192px;
  width: 192px;
  object-fit: cover;
  border-radius: 100%;
  border: 4px solid #eb1c22;
  margin-top: -6.32rem;
}
.player-information-h1 {
  font-weight: bold;
  color: black;
  padding: 1rem;
  border-bottom: 4px solid #003366;
}
.center-player-info {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 3rem;
}
.player-info-container {
  /* border-top: 10px solid #eb1c22;
  border-bottom: 10px solid #eb1c22; */
  background-color: whitesmoke;
}
.player-info-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 1rem;
}
.player-info-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.player-info-col-left {
  width: 400px;
  padding: 1rem;
}
.player-info-col-right {
  padding: 1rem;
}
.player-info-col-left h5 {
  font-weight: bold;
  padding: 1px;
}
.player-info-col-right h5 {
  padding: 1px;
}
.player-info-col-right {
  padding: 1rem;
}

.paid {
  color: green;
}
.unpaid{
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  color:#eb1c22;
}

/* end of player-profile */

/* start of players Landing page */
.players-landing-container-main {
  padding: 0 !important;
  background-color: #003366;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
}
.players-landing-img-h1 {
  background-color: #003366;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  border-bottom: 6px solid #eb1c22;
  position: relative;
  margin-bottom: 7rem;
}
.player-landing-h1 {
  position: absolute;
  color: white;
  font-weight: bold;
  font-size: 4rem;
  padding-bottom: 3.5rem;
  bottom: 7rem;
  background-color: transparent;
  text-shadow: 2px 3px #eb1c22;
}
.players-landing-img {
  height: 500px;
  width: 500px;
  -webkit-filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.7));
}
.players-landing-nynfc-img {
  height: 200px;
  width: 200px;
  position: absolute;
  bottom: -6.5rem;
}
.players-landing-container {
  /* padding: 3rem; */
  padding-bottom: 5rem;
  margin-bottom: 2rem;
}
.players-landing-h1s {
  background-color: #003366;
  font-weight: bold;
  font-size: 2.5rem;
  padding: 0.5rem;
  color: white;
  padding-bottom: 1rem;
  /* border:3px solid white; */
  border-bottom: 3px solid #eb1c22;
}
/* end of players Landing page */

/* start of playerCarousel */

.item {
  margin: 1px !important;
}
.carousel-card {
  width: 280px;
  border: 1px solid red;
  background-color: whitesmoke;
  color: black;
  box-shadow: 0 0 30px black;
  margin: 10px !important;
  border-radius: 5%;
}
.carousel-card h3 {
  font-weight: bold;
}
.carousel-card h1 {
  font-weight: bold;
  font-size: 4rem;
  color: #eb1c22;
  line-height: 0.5;
  padding-bottom: 10px;
}
.carousel-img {
  height: 300px;
  background-size: contain;
  border-top-left-radius: 5%;
  border-top-right-radius: 5%;
}

/* end of playerCarousel */

/* Start of media  queries */

@media (max-width: 500px) {
  .nynfc-parallex-img {
    height: 300px;
    width: 300px;
  }
  .parallex-container h1 {
    font-size: 2.6rem;
    margin-top: 0;
  }
  .parallex-container h3 {
    font-size: 1.4rem;
  }
  .about-us-desc {
    margin-top: -3.4rem;
  }
  #about-us p {
    text-align: justify !important;
    padding: 0 0.6rem;
  }
  .p-5 {
    padding: 0.1rem !important;
  }
  #lama-acc-heading {
    font-size: 1rem !important;
  }
  #mustang-thakali-heading {
    font-size: 2rem !important;
  }
}
@media only screen and (max-width: 600px) {
  .center-player-info {
    margin-top: 1rem;
  }
  .carousel-item {
    height: 100vh;
  }
  .about {
    font-size: 6rem;
  }
  .about-us {
    font-size: 2rem;
  }
  #trophies-heading::before {
    width: 18rem;
  }
  #executive-heading::before {
    width: 20rem;
  }
  #lama-acc-heading {
    font-size: 2.5rem;
  }
  .timeline h1 {
    font-size: 1.2rem;
  }

  .timeline .date::before {
    font-size: 0.8rem;
  }
  .timeline ul {
    padding: 5rem 3rem 5rem 3rem;
  }
  .timeline .date::before {
    left: -6rem;
  }
  .timeline .date::after {
    left: -3.2rem;
  }
  #medal-img {
    left: -10%;
  }

  .player-info-col-left {
    width: auto;
  }
  .nynfc-profile-img {
    height: 150px;
  }
  .player-profile-row h1 {
    font-size: 2rem;
    padding-top: 0.5rem;
  }
  .social-media {
    font-size: 1.2rem;
  }
  .player-information-h1 {
    font-size: 1.8rem;
    padding: 4px;
  }
  .player-info-col-left h5 {
    font-size: 1rem;
  }
  .player-info-col-right h5 {
    font-size: 1rem;
  }
  .player-info-container {
    margin-bottom: 3rem;
  }
  .player-profile-img {
    height: 150px;
    width: 150px;
  }
  .player-profile-img {
    margin-top: -5.32rem;
  }
  .google-maps {
    display: none;
  }
  .players-landing-container {
    padding: 1rem;
  }
  .players-landing-img {
    width: 250px;
    height: 250px;
    margin-bottom: 1rem;
  }
  .player-landing-h1 {
    font-size: 2rem;
    bottom: 3rem;
  }
  .players-landing-nynfc-img {
    width: 150px;
    height: 150px;
    bottom: -5rem;
  }
  .players-landing-img-h1 {
    margin-bottom: 4rem;
  }
  .carousel-card {
    margin-left: 2rem !important;
  }
}

/* ipad */
@media (max-width: 800px) {
  .about {
    font-size: 4rem;
    margin-top: 3rem;
  }
  .about-us {
    font-size: 1.5rem;
    line-height: 0;
    margin-top: -0.2rem;
  }
  #about-us p {
    margin-top: -2.5rem;
  }
  #lama-acc-heading {
    font-size: 2.5rem;
  }
  #mustang-thakali-heading {
    font-size: 2.4rem;
  }
}
/* ipad  portrait*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .news-img {
    width: 150px;
    height: 150px;
  }
}
/* end of media  queries*/

.contact-us-fluid {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 1rem 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
#contact-us-title {
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}
#contact-us-title::before,
#contact-us-title::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  background-color: #eb1c22;
}
#contact-us-title::before {
  width: 15rem;
  height: 3px;
}
#contact-us-title::after {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  bottom: -1.14rem;
}

.contact-us-container {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: stretch;
}

.contact-us-google-map {
  width: 100%;
  height: 100%;
  min-height: 300px;
}
.contact-us-form {
  width: 100%;
  background: #003366;
  padding: 1.5rem 1rem;
}

input,
textarea {
  width: 90%;
  border: none;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 1px solid white;
  color: white;
  font-size: 12px;
  margin-bottom: 0.8rem;
}

input:focus,
textarea:focus {
  outline: none;
}

.contact-submit {
  background-color: transparent;
  border: #eb1c22 1px solid;
  color: #eb1c22;
  transition: 0.4s ease;
  width: 80%;
  max-width: 300px;
  border-radius: 0;
  transition: all 0.3s ease-in;
  margin-top: 1rem;
}
.contact-submit:hover {
  background-color: #eb1c22;
  color: white;
  opacity: 0.7;
}

.contact-smedia-container {
  padding: 0;
  margin: 2rem 0;
  margin-bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.contact-icon-fb {
  color: #003366;
  margin-right: 5px;
  transition: all 0.3s ease-in;
}
.contact-icon-phone {
  color: green;
  transition: all 0.3s ease-in;
}
.contact-icon-in {
  color: #f56040;
  margin-right: 5px;
  transition: all 0.3s ease-in;
}
.con-icon:hover {
  opacity: 0.5;
}

@media screen and (max-width: 770px) {
  .contact-us-container {
    grid-template-columns: repeat(1, 1fr);
  }
  #contact-us-title {
    font-size: 2.5rem;
  }
}
/* @media screen and (max-width: 570px) {
  .contact-us-map {
    width: 100%;
    max-width: 520px;
  }
  .contact-us-form {
    width: 100%;
    max-width: 520px;
  }
}

@media screen and (max-width: 520px) {
  .contact-us-map {
    width: 100%;
    max-width: 500px;
  }
  .contact-us-form {
    width: 100%;
    max-width: 500px;
  }
} */

@media screen and (max-width: 500px) {
  #contact-us-title {
    font-size: 2rem;
  }
}


.history-fluid{
    background-color:rgba(255,255,255, 0.85); 
    border-top: 1px solid white;
  }

  .history-wrapper{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      -webkit-align-items: center;
              align-items: center;
  }
  .game-history h1{
    font-weight: bold;
    font-size: 4rem;
    color:#003366;
  }
  .game-history h3 {
    font-weight: bold;
    font-size: 1.5rem;
    color:#EB1C22;
  }

@media screen and (max-width:900px){
    .game-history > h1{
        font-size: 3rem;
    }
    .game-history > h3 {
        font-size: 1.1rem;
    }
}

@media screen and (max-width:590px){
    .game-history > h1{
        font-size: 2.5rem;
    }
    .game-history > h3{
        font-size: 1rem;
    }
    .history-wrapper{
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 15px;
        row-gap: 15px;
    }
}
.team-fluid {
  background-color: rgba(0, 51, 102, 0.9);
  border-top: 1px solid white;
  color: white;
}

.team-fluid a {
  text-decoration: none;
}
.team-container {
  padding-left: 0;
  padding-right: 0;
  max-width: 1100px;
  width: 100%;
  margin: auto;
}

#our-team-heading {
  font-weight: 700;
  text-transform: uppercase;
}
#our-team-heading::before,
#our-team-heading::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  background-color: #eb1c22;
}
#our-team-heading::before {
  width: 15rem;
  height: 3px;
}
#our-team-heading::after {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  bottom: -1.14rem;
}

.team-card-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.team-card {
  /* box-shadow: 0 0 30px black; */
  border-radius: 0;
  transition: 0.3s ease;
  cursor: pointer;
  background-color: white;
  max-width: 270px;
  overflow: hidden;
}
.card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.team-card-inner {
  overflow: hidden;
  position: relative;
}

.card-img-top {
  border-radius: 0;
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  overflow: hidden;
  border-bottom-right-radius: 35%;
  /* border-top-left-radius: 35%; */
}
.card-img-top:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
.team-card-body {
  width: 100%;
  background: white;
  border: 1px solid white;
  padding: 20px 10px;
  /* margin-top: -10px; */
  z-index: 100px;
  position: relative;
  transition: 0.3s all ease-in;
}
.team-card-name {
  color: #003366;
}
.player-number {
  font-size: 3.5rem;
  font-weight: bold;
  transition: all 0.3s ease-in;
}

.player-number:hover {
  box-shadow: 0 0 30px black;
}
.team-card-body a {
  text-decoration: none !important;
}
.team-card-body a > h6 {
  color: #003366;
}
.fa-instagram {
  color: #f56040;
}
.team-card-body:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.card-captain-icon{
  position: absolute;
  left:5px;
  top: 5px;
}

@media screen and (max-width: 910px) {
  .card-img-top {
    height: 250px;
  }
  .team-card-body {
    margin-top: -50px;
  }
  .team-card-name {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 770px) {
  .team-card-wrapper {
    max-width: 550px;
    width: 100%;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
  }
  .team-card-name {
    font-size: 1.5rem;
  }
  .player-number {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 600px) {
  #our-team-heading {
    font-size: 1.8rem;
  }
  .team-card-name {
    font-size: 1.3rem;
  }
  .player-number {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 500px) {
  .team-card-body {
    padding: 5px;
  }
  .team-card-name {
    font-size: 1.2rem;
  }
  .card-img-top {
    height: 200px;
  }
}

@media screen and (max-width: 400px) {
  .player-number {
    margin-bottom: 0;
  }
  .team-card-name {
    margin-bottom: 0;
  }
  .card-img-top {
    height: 180px;
  }
  .team-card-body h6 {
    margin-bottom: 0;
    font-size: 0.9rem;
  }
  .player-number {
    font-size: 1.8rem;
    padding: 2px;
  }
}

.captain-icon{
    background-color: #ee5253;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    position: relative;
}

.captain-icon::before,
.captain-icon::after{
    content: '';
    position: absolute;
    width: 30%;
    height: 10px;
    background-color: white;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.captain-icon::before{
    left: 0;
    
}
.captain-icon::after{
    right: 0;
}

#club-trophies{
    /* background-color:#003366; */
    background-color:rgba(0,51,102,0.9);
    border-top: 1px solid white;
  }
  #trophies-heading::before,
  #trophies-heading::after{
    content:'';
    position: absolute;
    bottom:-.5rem;
    left:50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    /* background-color: rgba(255,255,255,0.75); */
    background-color: #EB1C22;
  }
  #trophies-heading::before {
    width:27rem;
    height: 3px;
  }
  #trophies-heading::after{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    bottom: -1.14rem;
  }
  .trophy-card-wrapper{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 10px;
      -webkit-column-gap: 10px;
              column-gap: 10px;
  }
  .trophy-card {
   max-width: 270px;
   width: 100%;
   margin: auto;
   background-color: #003366;
   border: 1px solid white !important;
  }
  .trophy-card-inner{
    background-color:lightgray;
  }
  .trophy-img {
    width: 100%;
    height: 250px;
    object-fit: contain;
  }
  .card-info{
      padding:1rem 0;
  }
  .trophy-card-body h1{
    font-weight: bold;
    color: lightgray;
    font-size: 2rem;
  }
  .trophy-card-body h2{
    font-weight: bold;
    color: gold;
  }
  
  @media screen and (max-width:997px){
      .trophy-card-wrapper{
          max-width: 550px;
          width: 100%;
          margin: auto;
      }
    .trophy-card-wrapper{
        grid-template-columns: repeat(2,1fr);
        grid-row-gap: 10px;
        row-gap: 10px;
    }
    .trophy-img{
        height: 220px;
    }
  }

  @media screen and (max-width:500px){
      .trophy-img{
          height: 200px;
      }
      .trophy-card-body h1{
          font-size: 1.5rem;
      }
      .trophy-card-body h2{
          font-size: 1rem;
      }

      #trophies-heading{
          font-size: 2rem;
      }
      #trophies-heading::before {
        width:15rem;
        height: 3px;
      }
  }

  @media screen and (max-width:400px){
      .trophy-img{
          height: 130px;
      }
      .trophy-card-body h1{
          font-size: 1.1rem;
      }
      .trophy-card-body h2{
          font-size: 1rem;
      }
      #trophies-heading{
        font-size: 1.5rem;
    }
    #trophies-heading::before {
        width:15rem;
        height: 3px;
      }
  }

  
  .gallery-section h1{
    text-align: center;
    text-transform: uppercase;
    color:#333;
  }
  .border{
    width: 400px;
    height: 4px;
    background: #333;
    margin: 20px auto;
  }
  
  .modal{
    display: none;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background: rgba(0,0,0,0.9);
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .modal .modal-img-container{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .img-fluid-custom{
    max-height: 90vh;
    max-width: 98vw;
    object-fit: contain;
  }
  .button-left{
    position: absolute;
    top: 50%;
    left: -5vw;
    background-color: transparent;
    color: white;
    z-index: 1;
  }
  .button-right{
    position: absolute;
    top: 50%;
    right: -5vw;
    background-color: transparent;
    color: white;
    z-index: 1;
  }
  .button-top{
    position: absolute;
    top: -5vh;
    right:-5vw;
    background-color: transparent;
    color: white;
    z-index: 1;
  } 
  
  
  

.full-gallery-main{
    background: #f1f1f1;
    max-width: 1100px;
    width: 100%;
    margin: auto;
  }
  .full-gallery-container{
    padding-top: 2rem;
  }
  .full-gallery-main h1{
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #333;
  }

  .gallery-section{
    max-width: 1100px;
    width: 100%;
    padding: 10px;
    background: #f1f1f1;
    margin: auto;
  }

  .inner-width {
    width: 100%;
    margin: auto;
    padding:0;
  }

.border{
  width: 80%;
  height: 4px;
  background: #333;
  margin: 60px auto;
}


.produce-gallery-img-wrapper{
    max-width: 1100px;
    width:100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
}

.produce-gallery-img-div{
    overflow: hidden;
}

.produce-gallery-img{
    min-height: 250px;
    max-height: 250px;
    width: 100%;
    object-fit: cover;
    transition: all .3s ease-in;
    cursor: pointer;
}


.produce-gallery-img:hover{
  -webkit-transform: scale(1.2) rotate(5deg);
          transform: scale(1.2) rotate(5deg);
}


/* modal */
.gallery-section h1{
  text-align: center;
  text-transform: uppercase;
  color:#333;
}
.border{
  width: 300px;
  height: 4px;
  background: #333;
  margin: 20px auto;
}

.modal{
  display: none;
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background: rgba(0,0,0,0.9);
  z-index: 1;
  width: 100%;
  height: 100%;
}
.modal .modal-img-container{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.img-fluid-custom{
  max-height: 90vh;
  max-width: 98vw;
  object-fit: contain;
}
.button-left{
  position: absolute;
  top: 50%;
  left: -5vw;
  background-color: transparent;
  color: white;
  z-index: 1;
}
.button-right{
  position: absolute;
  top: 50%;
  right: -5vw;
  background-color: transparent;
  color: white;
  z-index: 1;
}
.button-top{
  position: absolute;
  top: -5vh;
  right:-5vw;
  background-color: transparent;
  color: white;
  z-index: 1;
} 

/* end modal */



@media screen and (max-width:880px){
    .produce-gallery-img{
        min-height: 200px;
        max-height: 200px;
    }
}
@media screen and (max-width:770px){
    .produce-gallery-img{
        min-height: 180px;
        max-height: 180px;
    }
}

@media screen and (max-width:650px){
    .produce-gallery-img{
        min-height: 150px;
        max-height: 150px;
    }
}

@media screen and (max-width:600px){
    .produce-gallery-img{
        min-height: 130px;
        max-height: 130px;
    }

}

@media screen and (max-width:500px){
    .produce-gallery-img-wrapper{
        grid-template-columns: repeat(2,1fr);
    }
}

.gallery-fluid{
    background: rgba(255,255,255,.9)
}
.gallery-container{
    max-width: 1100px;
    width: 100%;
    margin: auto;
}
#gallery-heading {
    font-weight: bold;
    color:black;
    text-transform: uppercase;
    font-size: 2.5rem;
  }
  
  #gallery-heading::before,
  #gallery-heading::after{
    content:'';
    position: absolute;
    bottom:-.5rem;
    left:50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    background-color: #EB1C22;
  }
  #gallery-heading::before {
    width:15rem;
    height: 3px;
  }
  #gallery-heading::after{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    bottom: -1.14rem;
  }
  .gallery-button {
    background-color: transparent;
    border: #EB1C22 1px solid;
    color:#EB1C22;
  }
  .gallery-button:hover{
    background-color: #EB1C22;
    color:white;
    opacity: 0.7;
  }
 
  @media screen and (max-width:500px){
      #gallery-heading{
          font-size: 1.8rem;
      }
  }
  
.executive-bg {
  background-color: rgba(0, 51, 102, 0.9);
}
#executive-heading {
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}
#executive-heading::before,
#executive-heading::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  background-color: #eb1c22;
}
#executive-heading::before {
  width: 25rem;
  height: 3px;
}
#executive-heading::after {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  bottom: -1.14rem;
}
.executive-container {
  max-width: 830px;
  margin: auto;
}
.executive-cards-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  max-width: 830px;
  width: 100%;
  margin: auto;
}

.executive-card {
  background-color: white;
}

.executive-card-wrapper .card {
  border-radius: 0;
}

.executive-card-body h3 {
  font-size: 1.4rem;
}

.executive-card-body h5 {
  font-size: 1rem;
}

.executive-img-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
}
.executive-img-bg {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 78%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 100% 78%, 0% 100%);
  object-fit: cover;
  width: 101%;
  height: 12rem;
}
.executive-img {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -7rem;
  z-index: 1;
  border: 1px solid white;
}
.executive-img:hover {
  -webkit-transform: scale(1.1) !important;
          transform: scale(1.1) !important;
}

.executive-card-body {
  margin-top: -1rem;
}

@media screen and (max-width: 770px) {
  #executive-heading {
    font-size: 2.5rem;
  }
  .executive-img-bg {
    height: 10rem;
  }

  .executive-card-body h3 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 650px) {
  .executive-img-bg {
    height: 9rem;
  }
  .executive-img {
    height: 8rem;
    width: 8rem;
    margin-top: -5rem;
  }
  .executive-card-body h5 {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 600px) {
  #executive-heading {
    font-size: 1.5rem;
  }
  #executive-heading::before {
    width: 17rem;
  }
  .executive-cards-wrapper {
    grid-template-columns: repeat(2, 1fr);
    max-width: 550px;
  }
  .executive-img-bg {
    height: 10rem;
  }

  .executive-card-body h3 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 450px) {
  .executive-img-bg {
    height: 8rem;
  }
  .executive-img {
    height: 7rem;
    width: 7rem;
    margin-top: -5rem;
  }
  .executive-card-body h3 {
    font-size: 1.1rem;
  }
}


.training-session-container-main{
    color:white;
    background-color:rgba(0,51,102,0.9);
  }
  .training-session-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .training-session-innerdiv{
    opacity: .7;
  }
  #training-session-heading{
    font-weight: bold;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    position: relative;
    margin-top: 1rem;
    text-transform: uppercase;
  }
  /* #training-session-heading::after{
    content:"";
    width: 25rem;
    height: 2px;
    background-color: #EB1C22;
    position:absolute;
    top: 4rem;
    left: 50%;
    transform: translate(-50%);
  } */
  .training-session-wrapper{
    padding: 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border: 1px solid #EB1C22;
    margin-bottom: 1rem;
  }
  .training-session-wrapper  h6{
    color:#EB1C22;
  }

  .training-session-wrapper h2 {
    text-transform: uppercase;
  }
.training-session-info{
  border: 1px solid white;
}

.training-session-info:nth-child(1){
  margin-right: 2rem;
}
  .training-session-innerdiv{
    line-height: 0.6;
  }

  @media screen and (max-width:770px){
    .training-session-wrapper h2 {
      font-size: 1.8rem;
    }
    .training-session-wrapper h5{
      font-size: 1.1rem;
    }
    .training-session-info:nth-child(1){
      margin-right: 1rem;
    }
  }

  @media screen and (max-width:625px){
    .training-session-info:nth-child(1){
      margin-right: .5rem;
    }
    .training-session-wrapper h2 {
      font-size: 1.4rem;
    }
    .training-session-wrapper h5{
      font-size: 1rem;
    }
  }


  @media screen and (max-width:600px){
      #training-session-heading{
        font-size: 2rem;
      }
      #training-session-heading::after{
        width: 20rem;
      }
  }
@media screen and (max-width:530px){
  .training-session-wrapper{
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .training-session-info:nth-child(1){
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
.news-container-main{
    /* background-color: white; */
    background-color:rgba(0,51,102,0.8);
    padding:15px;
    text-transform: uppercase;
  }
  #news-heading{
    font-weight: bold;
    color:white;
    position: relative;
    margin-top: 1rem;
  }
  #news-heading::before,
  #news-heading::after{
    content:'';
    position: absolute;
    bottom:-1rem;
    left:50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    background-color: #EB1C22;
  }
  #news-heading::before{
    width:15rem;
    height: 3px;
  }
  #news-heading::after{
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
    bottom: -1.5rem;
  }
  .news-container{
    /* margin-top:2rem;
    display: flex;
    justify-content: center;
    align-items: stretch; */
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
    /* align-items: center; */
    padding: 3rem 0;
  }
  .news-img{
    width: 100%;
    object-fit: cover;
    max-width: 180px;
    height: 150px;
  }
  .news-inner{
    overflow: hidden;
    border:1px solid #EB1C22;
    /* margin-right: .5rem; */
    border-radius: 3%;
    /* box-shadow: 0 0 30px black;  */
    transition: all .3s ease-in;
  }
  .news-inner:hover{
    opacity: .8;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .news-col-left{  
    min-height: 300px;
    height: 100%;
    /* background-color: white; */
    /* padding: 6px 6px 6px 6px; */
    margin-bottom: 1.3rem;
    box-shadow: 0 0 30px black; 
  }
  .news-col-right{
    width: 100%;
    box-shadow: 0 0 30px black; 
    display: -webkit-flex; 
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .news-col-right-grid{
    display: grid;
    grid-template-columns: repeat(2,150px);
    max-width: 330px;
    width: 100%;
    min-height: 300px;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    /* margin-bottom: 1.5rem; */
    /* border:6px solid white; */
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    
  }
  .news-frame{
      height: 100%;
      width: 100%;
  }

  @media screen and (max-width:1000px){
      .news-container{
          margin-top: 2rem;
          max-width: 660px;
          grid-template-columns: 1fr;
      }
      .news-col-right-grid{
          grid-template-columns: repeat(4, 120px);
      }
      .news-img{
          height: 120px;
      }
  }

  @media screen and (max-width:770px){
      #news-heading{
          font-size: 2.5rem;
      }
  }

  @media screen and (max-width:660px){
    .news-col-right-grid{
      max-width: 270px;
        grid-template-columns: repeat(2, 120px);
    }
  }

  @media screen and (max-width:500px){
    #news-heading{
        font-size: 2rem;
    }
      .news-container{
          margin-top: 3rem;
          padding: 0 !important;
      }
      .news-inner{
          border: none;
      }
      .news-img{
          width: 100px;
          height: 100px;
      }
      .news-col-right-grid{
          margin-bottom: none;
      }
      .news-col-left{
        min-height: 250px;
        max-height: 250px;
      }
  }
.image-container{
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height:100vh;
    background-image: url(/static/media/nynfc_bg2.7247ea80.jpg);
  
    /* remove to put it scroll */
    background-attachment: scroll;
    overflow: hidden;
    -webkit-animation: changeOpacity 2s forwards;
            animation: changeOpacity 2s forwards;
    /* opacity: 0.1; */
  }
  @-webkit-keyframes changeOpacity {
      0%{
        right: 50%;
        left: 50%;
      }
      100%{
          opacity: 1;
      }
  }
  @keyframes changeOpacity {
      0%{
        right: 50%;
        left: 50%;
      }
      100%{
          opacity: 1;
      }
  }

  .opacity {
      height: 100vh;
      position: relative;
      width: 100%;
      background: rgba(0,51,102,.2);
      /* background: rgba(0,0,0,.3); */
      -webkit-animation: changeBackground 3s forwards;
              animation: changeBackground 3s forwards;
  }

  @-webkit-keyframes changeBackground{
      0%{
        background: rgba(0,0,0,0);
      }
      100%{

      }
  }

  @keyframes changeBackground{
      0%{
        background: rgba(0,0,0,0);
      }
      100%{

      }
  }
 
  .nynfc-h1{
    position: absolute;
    top:60%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    color: white;
    background-image: linear-gradient(to right,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
     );
    padding: .3rem 5rem;
    border-top:3px solid red;
    border-bottom: 3px solid red;
    box-shadow: 5px 10px 8px 10px #8d8b8b;
    -webkit-animation: drop 2s forwards;
            animation: drop 2s forwards;
  }
  @-webkit-keyframes drop {
      0%{
        top:-60%;
        left:50%;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        background-image: none;
      }
      100%{
        top:60%;
        left:50%;
      }
  }
  @keyframes drop {
      0%{
        top:-60%;
        left:50%;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        background-image: none;
      }
      100%{
        top:60%;
        left:50%;
      }
  }
  .white-border{
      border-top: 3px solid white;
      border-bottom: 3px solid white;
      padding: .3rem 5rem;
  }
  .blue-border{
      border-top: 3px solid blue;
      border-bottom: 3px solid blue;
      padding: .3rem 0;
  }

  .blue-border p {
      margin-top: .5rem;
      margin-bottom: -1.3rem;
      font-size: 2rem;
      font-family: 'Satisfy', cursive;
      color:gold;
  }
  .nynfc-h1 h1{
    font-family: 'Source Serif Pro', serif;
      font-size: 5rem;
      margin-bottom: -.5rem;
  }

  @-webkit-keyframes borderChange {

  }

  @keyframes borderChange {

  }

  @media (max-width:500px){
      .nynfc-h1{
          padding: .1rem, 1rem;
      }
      .white-border{
        padding: .3rem 1rem;
    }
    .nynfc-h1 h1{
          font-size: 3rem;
    }
    .blue-border{
        padding-top: 0;
    }
    .blue-border p{
        font-size: 1.5rem;
        margin-bottom: -1rem;
    }
  }
#scroll-mouse-btn{
    width: 35px;
    height: 60px;
    border: 2px solid #EB1C22;
    border-radius: 20px;
    position: relative;
}

#scroll-mouse-btn::before{
    content:'';
    position: absolute;
    width:5px;
    height:5px;
    border-radius: 50%;
    background-color: #333;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    top: 10px;
    -webkit-animation: scrollCircle 2s infinite ease-out;
            animation: scrollCircle 2s infinite ease-out;
}

@-webkit-keyframes scrollCircle {
    0% {
        opacity: 0;
        top: 10px;
    }
    20%{
     opacity: .9;
    }
    50%{
        opacity: .5;
    }
    100%{
        opacity: 0;
        background-color: #EB1C22;
        top: 40px;
    }
}

@keyframes scrollCircle {
    0% {
        opacity: 0;
        top: 10px;
    }
    20%{
     opacity: .9;
    }
    50%{
        opacity: .5;
    }
    100%{
        opacity: 0;
        background-color: #EB1C22;
        top: 40px;
    }
}

.about-fluid{
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    }
    
    .about-container{
        display: grid;
        grid-template-columns: .35fr .65fr;
        -webkit-align-items: center;
                align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding-left: .3rem;
        padding-right: .3rem;
    }
    
    
    .about-nynfc-logo{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }
    .about-nynfc-img{
        width:100%;
        max-width: 300px;
        object-fit: cover;
        height: auto;
    }
    
    .about-info{
        width: 100%;
    }
    .about-info-heading{
        text-align: center;
        color: black;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    
    .about-info-heading > h1{
        font-size: 6rem;
        color:black;
    }
    
    .txt-color {
        color: #EB1C22;
        font-size: 3rem;
        margin-left: -1.8rem;
    }
    
    
    .about-desc{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction:column;
                flex-direction:column;
        margin-top: 1rem;
    }
    
    
    .about-info-button{
        margin: auto;
        border: 1px solid #EB1C22;
        font-weight: bold;
        padding: 8px 16px;
        cursor: pointer;
        color: black;
        transition: all .3s ease-in;
        margin-top: 1rem;
        border-radius: 5px;
    }
    
    .about-info-button:hover{
        background-color: #EB1C22;
        color: white;
    }
    
    @media screen and (max-width:1008px){
        .about-container{
            grid-template-columns: 1fr;
        }
    }
    
    @media screen and (max-width:500px){
        .about-nynfc-img{
            max-width: 250px;
        }
            .about-info-heading > h1{
            font-size: 4rem;
            }
    
        .txt-color{
            font-size: 2rem;
            margin-left: -1.3rem;
        }
    }
.bg-whitesmoke{
    background-color: #f4f4f4;
}
.mainview-fluid{
    height: 100vh;
    background: #003366;
    background-image: url(/static/media/nynfc_bg.df41e24f.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 0;
    border-bottom-right-radius: 40%;
    /* border-top-left-radius: 30%; */
}

.mainview-fluid::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    border-bottom-right-radius: 40%;
    /* border-top-left-radius: 30%; */
}

.mainview-container{
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.mainview-card{
    background-image: url(/static/media/nynfc_bg.df41e24f.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 90%;
    height: 500px;
    border: 2px solid white;
    color:white;
    border-top-left-radius: 80px;
    border-bottom-right-radius: 80px;
    box-shadow: 1px 2px 60px rgba(0,0,0,.8);
    position: relative;
    overflow: hidden;
    z-index: 15;
}

.mainview-card-inner{
    background: rgba(0,0,0,.6);
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

.mainview-card-inner > h1{
    /* background:rgba(0,0,0,.5); */
    font-family: 'Playfair Display', serif;
    font-weight: bold;
    font-size: 6rem;
}
.mainview-button{
    background:#EB1C22;
    border: 1px solid white;
    padding: .5rem .8rem;
    cursor: pointer;
    border-radius: 5px;
    transition: all .3s ease-in;
    margin-bottom: 1.8rem;
}
.mainview-button:hover{
    opacity: .8;
}

.mainview-card-inner > p {
    font-family: 'Dancing Script', cursive;
    font-size: 2rem;
    margin-bottom: -1.8rem;
    /* color:rgba(220, 53, 69, 1) */
}
.arrow{
    margin-top: 8px;
}
.arrow span{
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 5px solid #EB1C22;
    border-right: 5px solid #EB1C22;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin: -10px;
    -webkit-animation: animate 2s infinite;
            animation: animate 2s infinite;
}
.arrow span:nth-child(2){
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
}
@-webkit-keyframes animate {
    0%{
        opacity: 0;
        -webkit-transform: rotate(45deg) translate(-20px,-20px);
                transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        -webkit-transform: rotate(45deg) translate(20px,20px);
                transform: rotate(45deg) translate(20px,20px);
    }
}
@keyframes animate {
    0%{
        opacity: 0;
        -webkit-transform: rotate(45deg) translate(-20px,-20px);
                transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        -webkit-transform: rotate(45deg) translate(20px,20px);
                transform: rotate(45deg) translate(20px,20px);
    }
}
@media screen and (max-width:1008px){
    .mainview-fluid{
        border-bottom-right-radius: 30%;
    }
    .mainview-fluid::before{
        border-bottom-right-radius: 30%;
    }
}


@media screen and (max-width:600px){
    .mainview-card-inner > h1{ 
        font-size: 4rem;
    }
    .mainview-card{
        height: 300px;
    }
}

#sticky-footer {
  position: relative;
  -webkit-flex-shrink: none;
          flex-shrink: none;
  color: white;
  border: 1px solid gray;
  background-color: rgba(0, 51, 102, 0.95) !important;
  bottom: 0 !important;
  left: 0 !important;
}

.footer-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.footer-container p {
  font-size: 0.8rem;
}

.footer-container p:nth-child(2) a {
  color: white;
  text-decoration: none;
}

.up {
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-color: #eee;
  top: -1.5rem;
  right: 1rem;
  border-radius: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
  cursor: pointer;
}
.up i {
  color: #eb1c22;
  font-size: 2rem;
  margin: auto;
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #eb1c22;
    opacity: 1;
  }
  70% {
    box-shadow: 0 0 0 1rem #eb1c22;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 0 #eb1c22;
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #eb1c22;
    opacity: 1;
  }
  70% {
    box-shadow: 0 0 0 1rem #eb1c22;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 0 #eb1c22;
    opacity: 1;
  }
}

@media screen and (max-width: 410px) {
  .footer-container {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .footer-container p {
    margin-bottom: 0;
    font-size: 0.8rem;
  }
}

.trophy-history-container-main {
  background-color: rgba(0, 51, 102, 0.9);
  text-align: center;
  padding-left: 12rem !important;
  position: relative;
  overflow: hidden;
}
.trophy-history-bg {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: -1;
}
.trophy-history-container {
  background-color: transparent;
  color: white;
  position: relative;
}
#trophy-history-heading {
  color: white;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 1rem;
  font-weight: bold;
}

#trophy-history-heading::before,
#trophy-history-heading::after {
  content: '';
  position: absolute;
  bottom: -15rem;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  background-color: #eb1c22;
}
#trophy-history-heading::before {
  width: 15rem;
  height: 3px;
}
#trophy-history-heading:after {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  bottom: -15.6rem;
}
.timeline ul {
  border-left: 4px solid #eb1c22;
  border-radius: 0.8rem;
  position: relative;
  padding: 6rem 4rem 6rem 4rem;
  list-style: none;
  text-align: left !important;
}

.timeline h1 {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 1rem;
  opacity: 1rem;
  display: inline;
}

.fa-trophy {
  color: #fcb434 !important;
  font-size: 1.8rem;
  padding-right: 10px;
  margin-bottom: 17px;
}
.fa-trophy-silver {
  color: silver !important;
}
.fa-trophy-bronze {
  color: #b08d57;
}
.timeline .date {
  margin-bottom: 1rem;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  color: white;
  position: relative;
}

.timeline .date:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
.timeline .date::before,
.timeline .date::after {
  position: absolute;
  display: block;
  top: 20%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

.timeline .date::before {
  content: attr(date-date);
  left: -7rem;
  text-align: right !important;
  font-size: 1rem;
  /* top: 7px; */
  -webkit-transform: translate(-50%, -20%);
          transform: translate(-50%, -20%);
}

.timeline .date::after {
  content: '';
  box-shadow: 0 0 0 4px white;
  border-radius: 100%;
  left: -4.2rem;
  height: 1rem;
  width: 1rem;
  background-color: #eb1c22;
}

#medal-img {
  width: 300px;
  height: 200px;
  margin-bottom: 4rem;
  position: relative;
}

@media screen and (max-width: 768px) {
  .timeline .date::before {
    left: -7rem;
  }
}

@media screen and (max-width: 768px) {
  .timeline h1 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 540px) {
  .trophy-history-container-main {
    padding-left: 8rem !important;
  }
}

@media screen and (max-width: 450px) {
  .timeline h1 {
    font-size: 1.2rem;
  }
  .timeline ul {
    padding: 6rem 2rem 6rem 2rem;
  }
  .timeline .date::after {
    left: -2.2rem;
  }
  .timeline .date::before {
    left: -5rem;
  }
  .trophy-history-container-main {
    padding-left: 6rem !important;
  }
}

@media screen and (max-width: 432px) {
  .trophy-history-container-main {
    padding-left: 4rem !important;
    overflow: hidden;
  }
  .timeline .date::after {
    height: 0.8rem;
    width: 0.8rem;
  }
  .fa-trophy,
  .fa-trophy-bronze,
  .fa-trophy-silver {
    font-size: 1.3rem;
  }
  .timeline ul {
    padding: 6rem 1.5rem 6rem 1rem;
  }
  .timeline .date::after {
    left: -1.1rem;
  }
  .timeline .date::before {
    left: -3.5rem;
  }
}

.executive-list-main-container {
  background-color: rgb(0, 51, 102);
  padding-bottom: 3rem;
  min-height: 95vh;
}
.executive-list-logo-top {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  border-bottom: 6px solid #eb1c22;
  padding-bottom: 1.5rem;
}
.executive-list-logo {
  object-fit: cover;
  width: 100%;
  max-width: 300px;
}
.executive-list-logo-top > h2 {
  color: white;
  font-weight: bold;
  font-size: 2.8rem;
  margin-top: -3rem;
  background-color: transparent;
  text-shadow: 5px 3px #eb1c22;
}
.executive-list-fluid {
  margin-top: 3rem;
}
.executive-card-wrapper > a{
  text-decoration: none !important;
  color: #505962;
}
.executive-card-body {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.executive-list-container {
  padding: 0;
}
@media screen and (max-width: 600px) {
  .executive-list-logo-top > h2 {
    font-size: 2.2rem;
    margin-top: -1.5rem;
  }
  .executive-list-logo {
    max-width: 180px;
  }
  .executive-list-logo-top {
    border-bottom-width: 4px;
  }
  .executive-list-fluid {
    margin-top: 2rem;
  }
  .executive-list-logo-top {
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .executive-list-logo-top > h2 {
    font-size: 2rem;
  }
  .executive-list-logo {
    max-width: 160px;
  }
}

.finance-page {
  background-color: rgb(0, 51, 102);
  padding-bottom: 2rem;
}
.finance-image-container {
  background-image: url(/static/media/finance.6d56c467.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  border-bottom-right-radius: 25%;
}

.finance-opacity {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-bottom-right-radius: 25%;
}
.finance-info-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  color: white;
}
.balance {
  text-align: center;
}
.balance > p {
  font-size: 1.2rem;
  margin-bottom: 0;
}
.balance > h2 {
  /* color: #009966; */
  font-weight: bold;
}
.income-info,
.expense-info {
  width: 300px;
  padding: 4px 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.income-info > p,
.expense-info > p {
  margin-bottom: 0;
  font-weight: bold;
}

.income-info {
  background-color: rgba(0, 153, 102, 0.7);
  margin-bottom: 1rem;
}
.expense-info {
  background-color: rgba(255, 103, 1, 0.7);
}

/* Start Form */
.finance-form-fluid {
  margin-top: 4rem;
}
.finance-form-border {
  position: relative;
  padding: 15px;
  border: 1px solid white;
  max-width: 450px;
  width: 100%;
  margin: auto;
}
.finance-form-heading {
  width: 260px;
  color: white;
  font-weight: 700;
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgb(0, 51, 102);
  padding: 2px 6px;
}
.finance-form-heading > h3 {
  font-size: 1.6rem;
}
.finance-form {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  max-width: 400px;
}
.finance-submit {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.finance-submit-btn {
  width: 150px;
  border-radius: 0;
}
.finance-form-input {
  width: 100% !important;
}
@media screen and (max-width: 700px) {
  .finance-form-fluid {
    padding: 0;
  }
  .finance-form-heading > h3 {
    font-size: 1.4rem;
  }
}
/* End Form */

/* Start Transactions */
.transaction-fluid {
  margin-top: 4rem;
  padding: 0;
  color: white;
  border-top: 2px solid white;
  padding-top: 1.2rem;
}
.transaction-container {
  margin-top: 1.2rem;
  max-width: 900px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}
.accordion-transaction-card {
  border-radius: 0;
  margin-bottom: 0.5rem;
  color: rgb(0, 51, 102);
}
.accordion-transaction-card:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.transaction-card-header {
  padding: 1px 1px 1px 5px;
}
.transaction-card-pannel > p {
  margin-bottom: 0;
  font-size: 1rem;
  overflow: hidden;
}
.transaction-income-heading {
  text-transform: uppercase;
  color: rgba(0, 153, 102, 1);
  text-align: center;
  margin-bottom: 1rem;
}
.transaction-expense-heading {
  text-transform: uppercase;
  color: rgba(255, 103, 1, 1);
  text-align: center;
  margin-bottom: 1rem;
}
@media screen and (max-width: 700px) {
  .transaction-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .transaction-income-heading {
    font-size: 1.2rem;
  }
  .transaction-expense-heading {
    font-size: 1.2rem;
  }
}
.filter-date-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.filter-input > select {
  width: 230px;
  border: 0;
  border-radius: 0;
  background-color: rgb(0, 51, 102);
  border: 1px solid white;
  color: white;
  padding: 7px 5px;
}
.filter-input > select:focus {
  outline: none;
}
.income-clr {
  color: rgba(0, 153, 102, 1);
  font-size: 0.8rem;
}
.expense-clr {
  color: rgba(255, 103, 1, 1);
  font-size: 0.8rem;
}
/* End Transactions */

/* start edit transaction form */
.edit-finance-fluid {
  padding: 0;
  background-color: rgb(0, 51, 102);
}
.finance-collapse{
  background-color: rgb(0, 51, 102);
}
/* end edit transaction form */

.center-everything {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.edit-executives-container {
  border-top: 1px solid black;
}
.edit-executives-heading {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
}
.accordion-card {
  min-width: 600px;
  max-width: 500px;
  border-radius: 0;
  margin-bottom: 0.5rem;
}
.accordion-pannel {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.accordion-pannel h5 {
  font-weight: bold;
  margin-bottom: 0;
}
.accordion-btn-group button {
  border-radius: 0;
  border: none;
}
.accordion-btn-group button:focus {
  outline: none;
}
.accordion-card:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.executive-upload-form-custom {
  padding: 1rem !important;
}
.image-preview-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 1rem;
}
.executive-preview-img {
  max-width: 150px;
  object-fit: cover;
}
.executive-upload-button:focus {
  outline: none;
}

.executive-upload-container {
  margin-top: 50px;
  min-height: 95vh;
}
.executive-upload-heading-bg {
  background-image: url(/static/media/nynfc_logo.488e7e52.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 150px;
  margin-bottom: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.executive-upload-form {
  margin: auto;
  min-width: 500px;
}

.executive-upload-form label {
  text-align: start !important;
}
.executive-upload-heading {
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 0 0.2rem;
  border: 3px solid #003366;
}
.executive-upload-form input[type='text'],
.executive-upload-form input[type='number'],
.executive-upload-form input[type='email'],
.executive-upload-form select,
.executive-upload-form textarea,
.executive-upload-form input[type='file'] {
  display: block;
  outline: none;
  width: 100%;
  padding: 0.2rem;
  font-size: 1rem;
  background-color: transparent;
  color: black !important;
  border: none;
  border-bottom: 1px solid #003366;
  border-radius: 0;
}
.executive-upload-form input[type='text']:focus,
.executive-upload-form input[type='number']:focus,
.executive-upload-form input[type='email']:focus,
.executive-upload-form select:focus,
.executive-upload-form textarea:focus,
.executive-upload-form input[type='file']:focus {
  -webkit-appearance: none;
  /* outline: none; */
}

.executive-upload-button {
  background-color: #003366;
  transition: 0.3s all ease-in;
  border-radius: 0;
}
.executive-upload-button:hover {
  background-color: #003366;
  opacity: 0.8;
}
.center-button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
/* @media screen and (max-width: 500px) {
  .form-group {
    min-width: 400px;
  }
}
@media screen and (max-width: 400px) {
  .form-group {
    width: 350px;
  }
} */

@media screen and (max-width: 550px) {
  .executive-upload-form {
    min-width: 100px;
  }
}

.player-upload-container {
  margin-top: 50px;
  min-height: 95vh;
}
.player-upload-heading-bg {
  background-image: url(/static/media/nynfc_logo.488e7e52.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 150px;
  margin-bottom: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.player-upload-form {
  margin: auto;
  min-width: 500px;
}

.player-upload-form label {
  text-align: start !important;
}
.player-upload-heading {
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 0 0.2rem;
  border: 3px solid #003366;
}
.form-group {
  min-width: 500px;
}
.player-upload-form input[type='text'],
.player-upload-form input[type='number'],
.player-upload-form input[type='email'],
.player-upload-form select,
.player-upload-form textarea,
.player-upload-form input[type='file'] {
  display: block;
  outline: none;
  width: 100%;
  padding: 0.2rem;
  font-size: 1rem;
  background-color: transparent;
  color: black !important;
  border: none;
  border-bottom: 1px solid #003366;
  border-radius: 0;
}
.player-upload-form input[type='text']:focus,
.player-upload-form input[type='number']:focus,
.player-upload-form input[type='email']:focus,
.player-upload-form select:focus,
.player-upload-form textarea:focus,
.player-upload-form input[type='file']:focus {
  -webkit-appearance: none;
  /* outline: none; */
}

#ageId {
  width: 50px !important;
}

.height-info {
  display: -webkit-flex;
  display: flex;
  text-align: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: baseline;
          align-items: baseline;
}
.height-info > p {
  margin-bottom: 0;
  margin-right: 5px;
  font-weight: bold;
}
.height-info input {
  width: 40px !important;
}

.player-upload-button {
  background-color: #003366;
  transition: 0.3s all ease-in;
  border-radius: 0;
}
.player-upload-button:focus {
  outline: none;
}
.player-upload-button:hover {
  background-color: #003366;
  opacity: 0.8;
}
.center-button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

@media screen and (max-width: 550px) {
  .player-upload-form {
    min-width: 100px;
  }
}

.center-everything {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.edit-players-container {
  border-top: 1px solid black;
}
.edit-players-heading {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
}
.accordion-card {
  min-width: 600px;
  border-radius: 0;
  margin-bottom: 0.5rem;
}
.accordion-pannel {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.accordion-pannel h5 {
  font-weight: bold;
  margin-bottom: 0;
}
.accordion-btn-group button {
  border-radius: 0;
  border: none;
}
.accordion-btn-group button:focus {
  outline: none;
}
.accordion-card:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.player-upload-form-custom {
  padding: 1rem !important;
}
.image-preview-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 1rem;
}
.preview-img {
  max-width: 150px;
  object-fit: cover;
}

@media screen and (max-width: 550px) {
  .accordion-card {
    min-width: 330px;
  }
}

.training-schedule-container {
  margin-top: 83px;
  height: 100%;
  padding: 0;
}
.training-upload-heading-bg {
  background-image: url(/static/media/nynfc_logo.488e7e52.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 150px;
  margin-bottom: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.training-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.training-schedule-form .form-group > input {
  border-radius: 0;
}
.training-schedule-form .form-group > input:focus {
  outline: none;
}

.line-break {
  border-top: 1px solid black;
}
.center-training-button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.center-training-button > button {
  border: 0;
  padding: 8px 20px;
  margin-top: 1rem;
  background-color: #e5bc3b;
  cursor: pointer;
  transition: 0.3s all ease-in;
  color: black;
}
.center-training-button > button:hover {
  opacity: 0.8;
}
.d-flex input:nth-child(1) {
  margin-right: 0.2rem;
}
.training-schedule-form {
  min-width: 600px;
}
@media screen and (max-width: 550px) {
  .training-schedule-form {
    min-width: 100px;
  }
}

.admin-nav-fluid {
  min-height: 100vh;
}
.admin-nav-container {
  margin-top: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.admin-nav-container > a {
  text-decoration: none;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  border: 2px solid #003366;
  padding: 0.4rem;
}

.admin-nav-container > a:nth-child(1) {
  border-right: 0;
}
.admin-nav-container > a:nth-child(2) {
  border-right: 0;
}
.active-link {
  background-color: #003366;
  color: white !important;
}

