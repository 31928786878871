.executive-bg {
  background-color: rgba(0, 51, 102, 0.9);
}
#executive-heading {
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}
#executive-heading::before,
#executive-heading::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translate(-50%);
  background-color: #eb1c22;
}
#executive-heading::before {
  width: 25rem;
  height: 3px;
}
#executive-heading::after {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  bottom: -1.14rem;
}
.executive-container {
  max-width: 830px;
  margin: auto;
}
.executive-cards-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;
  column-gap: 10px;
  max-width: 830px;
  width: 100%;
  margin: auto;
}

.executive-card {
  background-color: white;
}

.executive-card-wrapper .card {
  border-radius: 0;
}

.executive-card-body h3 {
  font-size: 1.4rem;
}

.executive-card-body h5 {
  font-size: 1rem;
}

.executive-img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.executive-img-bg {
  clip-path: polygon(0 0, 100% 0, 100% 78%, 0% 100%);
  object-fit: cover;
  width: 101%;
  height: 12rem;
}
.executive-img {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -7rem;
  z-index: 1;
  border: 1px solid white;
}
.executive-img:hover {
  transform: scale(1.1) !important;
}

.executive-card-body {
  margin-top: -1rem;
}

@media screen and (max-width: 770px) {
  #executive-heading {
    font-size: 2.5rem;
  }
  .executive-img-bg {
    height: 10rem;
  }

  .executive-card-body h3 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 650px) {
  .executive-img-bg {
    height: 9rem;
  }
  .executive-img {
    height: 8rem;
    width: 8rem;
    margin-top: -5rem;
  }
  .executive-card-body h5 {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 600px) {
  #executive-heading {
    font-size: 1.5rem;
  }
  #executive-heading::before {
    width: 17rem;
  }
  .executive-cards-wrapper {
    grid-template-columns: repeat(2, 1fr);
    max-width: 550px;
  }
  .executive-img-bg {
    height: 10rem;
  }

  .executive-card-body h3 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 450px) {
  .executive-img-bg {
    height: 8rem;
  }
  .executive-img {
    height: 7rem;
    width: 7rem;
    margin-top: -5rem;
  }
  .executive-card-body h3 {
    font-size: 1.1rem;
  }
}
