.center-everything {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.edit-executives-container {
  border-top: 1px solid black;
}
.edit-executives-heading {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
}
.accordion-card {
  min-width: 600px;
  max-width: 500px;
  border-radius: 0;
  margin-bottom: 0.5rem;
}
.accordion-pannel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion-pannel h5 {
  font-weight: bold;
  margin-bottom: 0;
}
.accordion-btn-group button {
  border-radius: 0;
  border: none;
}
.accordion-btn-group button:focus {
  outline: none;
}
.accordion-card:hover {
  transform: scale(1);
}
.executive-upload-form-custom {
  padding: 1rem !important;
}
.image-preview-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.executive-preview-img {
  max-width: 150px;
  object-fit: cover;
}
.executive-upload-button:focus {
  outline: none;
}
