.contact-us-fluid {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
#contact-us-title {
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}
#contact-us-title::before,
#contact-us-title::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translate(-50%);
  background-color: #eb1c22;
}
#contact-us-title::before {
  width: 15rem;
  height: 3px;
}
#contact-us-title::after {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  bottom: -1.14rem;
}

.contact-us-container {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: stretch;
}

.contact-us-google-map {
  width: 100%;
  height: 100%;
  min-height: 300px;
}
.contact-us-form {
  width: 100%;
  background: #003366;
  padding: 1.5rem 1rem;
}

input,
textarea {
  width: 90%;
  border: none;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 1px solid white;
  color: white;
  font-size: 12px;
  margin-bottom: 0.8rem;
}

input:focus,
textarea:focus {
  outline: none;
}

.contact-submit {
  background-color: transparent;
  border: #eb1c22 1px solid;
  color: #eb1c22;
  transition: 0.4s ease;
  width: 80%;
  max-width: 300px;
  border-radius: 0;
  transition: all 0.3s ease-in;
  margin-top: 1rem;
}
.contact-submit:hover {
  background-color: #eb1c22;
  color: white;
  opacity: 0.7;
}

.contact-smedia-container {
  padding: 0;
  margin: 2rem 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-icon-fb {
  color: #003366;
  margin-right: 5px;
  transition: all 0.3s ease-in;
}
.contact-icon-phone {
  color: green;
  transition: all 0.3s ease-in;
}
.contact-icon-in {
  color: #f56040;
  margin-right: 5px;
  transition: all 0.3s ease-in;
}
.con-icon:hover {
  opacity: 0.5;
}

@media screen and (max-width: 770px) {
  .contact-us-container {
    grid-template-columns: repeat(1, 1fr);
  }
  #contact-us-title {
    font-size: 2.5rem;
  }
}
/* @media screen and (max-width: 570px) {
  .contact-us-map {
    width: 100%;
    max-width: 520px;
  }
  .contact-us-form {
    width: 100%;
    max-width: 520px;
  }
}

@media screen and (max-width: 520px) {
  .contact-us-map {
    width: 100%;
    max-width: 500px;
  }
  .contact-us-form {
    width: 100%;
    max-width: 500px;
  }
} */

@media screen and (max-width: 500px) {
  #contact-us-title {
    font-size: 2rem;
  }
}
