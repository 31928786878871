.gallery-fluid{
    background: rgba(255,255,255,.9)
}
.gallery-container{
    max-width: 1100px;
    width: 100%;
    margin: auto;
}
#gallery-heading {
    font-weight: bold;
    color:black;
    text-transform: uppercase;
    font-size: 2.5rem;
  }
  
  #gallery-heading::before,
  #gallery-heading::after{
    content:'';
    position: absolute;
    bottom:-.5rem;
    left:50%;
    transform: translate(-50%);
    background-color: #EB1C22;
  }
  #gallery-heading::before {
    width:15rem;
    height: 3px;
  }
  #gallery-heading::after{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    bottom: -1.14rem;
  }
  .gallery-button {
    background-color: transparent;
    border: #EB1C22 1px solid;
    color:#EB1C22;
  }
  .gallery-button:hover{
    background-color: #EB1C22;
    color:white;
    opacity: 0.7;
  }
 
  @media screen and (max-width:500px){
      #gallery-heading{
          font-size: 1.8rem;
      }
  }
  