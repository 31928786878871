#club-trophies{
    /* background-color:#003366; */
    background-color:rgba(0,51,102,0.9);
    border-top: 1px solid white;
  }
  #trophies-heading::before,
  #trophies-heading::after{
    content:'';
    position: absolute;
    bottom:-.5rem;
    left:50%;
    transform: translate(-50%);
    /* background-color: rgba(255,255,255,0.75); */
    background-color: #EB1C22;
  }
  #trophies-heading::before {
    width:27rem;
    height: 3px;
  }
  #trophies-heading::after{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    bottom: -1.14rem;
  }
  .trophy-card-wrapper{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 10px;
  }
  .trophy-card {
   max-width: 270px;
   width: 100%;
   margin: auto;
   background-color: #003366;
   border: 1px solid white !important;
  }
  .trophy-card-inner{
    background-color:lightgray;
  }
  .trophy-img {
    width: 100%;
    height: 250px;
    object-fit: contain;
  }
  .card-info{
      padding:1rem 0;
  }
  .trophy-card-body h1{
    font-weight: bold;
    color: lightgray;
    font-size: 2rem;
  }
  .trophy-card-body h2{
    font-weight: bold;
    color: gold;
  }
  
  @media screen and (max-width:997px){
      .trophy-card-wrapper{
          max-width: 550px;
          width: 100%;
          margin: auto;
      }
    .trophy-card-wrapper{
        grid-template-columns: repeat(2,1fr);
        row-gap: 10px;
    }
    .trophy-img{
        height: 220px;
    }
  }

  @media screen and (max-width:500px){
      .trophy-img{
          height: 200px;
      }
      .trophy-card-body h1{
          font-size: 1.5rem;
      }
      .trophy-card-body h2{
          font-size: 1rem;
      }

      #trophies-heading{
          font-size: 2rem;
      }
      #trophies-heading::before {
        width:15rem;
        height: 3px;
      }
  }

  @media screen and (max-width:400px){
      .trophy-img{
          height: 130px;
      }
      .trophy-card-body h1{
          font-size: 1.1rem;
      }
      .trophy-card-body h2{
          font-size: 1rem;
      }
      #trophies-heading{
        font-size: 1.5rem;
    }
    #trophies-heading::before {
        width:15rem;
        height: 3px;
      }
  }