#sticky-footer {
  position: relative;
  flex-shrink: none;
  color: white;
  border: 1px solid gray;
  background-color: rgba(0, 51, 102, 0.95) !important;
  bottom: 0 !important;
  left: 0 !important;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-container p {
  font-size: 0.8rem;
}

.footer-container p:nth-child(2) a {
  color: white;
  text-decoration: none;
}

.up {
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-color: #eee;
  top: -1.5rem;
  right: 1rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 2s infinite;
  cursor: pointer;
}
.up i {
  color: #eb1c22;
  font-size: 2rem;
  margin: auto;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #eb1c22;
    opacity: 1;
  }
  70% {
    box-shadow: 0 0 0 1rem #eb1c22;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 0 #eb1c22;
    opacity: 1;
  }
}

@media screen and (max-width: 410px) {
  .footer-container {
    flex-direction: column;
  }
  .footer-container p {
    margin-bottom: 0;
    font-size: 0.8rem;
  }
}
