.team-fluid {
  background-color: rgba(0, 51, 102, 0.9);
  border-top: 1px solid white;
  color: white;
}

.team-fluid a {
  text-decoration: none;
}
.team-container {
  padding-left: 0;
  padding-right: 0;
  max-width: 1100px;
  width: 100%;
  margin: auto;
}

#our-team-heading {
  font-weight: 700;
  text-transform: uppercase;
}
#our-team-heading::before,
#our-team-heading::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translate(-50%);
  background-color: #eb1c22;
}
#our-team-heading::before {
  width: 15rem;
  height: 3px;
}
#our-team-heading::after {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  bottom: -1.14rem;
}

.team-card-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 10px;
}

.team-card {
  /* box-shadow: 0 0 30px black; */
  border-radius: 0;
  transition: 0.3s ease;
  cursor: pointer;
  background-color: white;
  max-width: 270px;
  overflow: hidden;
}
.card:hover {
  transform: scale(1.05);
}
.team-card-inner {
  overflow: hidden;
  position: relative;
}

.card-img-top {
  border-radius: 0;
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: transform 0.4s;
  overflow: hidden;
  border-bottom-right-radius: 35%;
  /* border-top-left-radius: 35%; */
}
.card-img-top:hover {
  transform: scale(1.5);
}
.team-card-body {
  width: 100%;
  background: white;
  border: 1px solid white;
  padding: 20px 10px;
  /* margin-top: -10px; */
  z-index: 100px;
  position: relative;
  transition: 0.3s all ease-in;
}
.team-card-name {
  color: #003366;
}
.player-number {
  font-size: 3.5rem;
  font-weight: bold;
  transition: all 0.3s ease-in;
}

.player-number:hover {
  box-shadow: 0 0 30px black;
}
.team-card-body a {
  text-decoration: none !important;
}
.team-card-body a > h6 {
  color: #003366;
}
.fa-instagram {
  color: #f56040;
}
.team-card-body:hover {
  transform: scale(1.2);
}

.card-captain-icon{
  position: absolute;
  left:5px;
  top: 5px;
}

@media screen and (max-width: 910px) {
  .card-img-top {
    height: 250px;
  }
  .team-card-body {
    margin-top: -50px;
  }
  .team-card-name {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 770px) {
  .team-card-wrapper {
    max-width: 550px;
    width: 100%;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
  }
  .team-card-name {
    font-size: 1.5rem;
  }
  .player-number {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 600px) {
  #our-team-heading {
    font-size: 1.8rem;
  }
  .team-card-name {
    font-size: 1.3rem;
  }
  .player-number {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 500px) {
  .team-card-body {
    padding: 5px;
  }
  .team-card-name {
    font-size: 1.2rem;
  }
  .card-img-top {
    height: 200px;
  }
}

@media screen and (max-width: 400px) {
  .player-number {
    margin-bottom: 0;
  }
  .team-card-name {
    margin-bottom: 0;
  }
  .card-img-top {
    height: 180px;
  }
  .team-card-body h6 {
    margin-bottom: 0;
    font-size: 0.9rem;
  }
  .player-number {
    font-size: 1.8rem;
    padding: 2px;
  }
}
