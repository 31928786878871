
.history-fluid{
    background-color:rgba(255,255,255, 0.85); 
    border-top: 1px solid white;
  }

  .history-wrapper{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
  }
  .game-history h1{
    font-weight: bold;
    font-size: 4rem;
    color:#003366;
  }
  .game-history h3 {
    font-weight: bold;
    font-size: 1.5rem;
    color:#EB1C22;
  }

@media screen and (max-width:900px){
    .game-history > h1{
        font-size: 3rem;
    }
    .game-history > h3 {
        font-size: 1.1rem;
    }
}

@media screen and (max-width:590px){
    .game-history > h1{
        font-size: 2.5rem;
    }
    .game-history > h3{
        font-size: 1rem;
    }
    .history-wrapper{
        grid-template-columns: repeat(2, 1fr);
        row-gap: 15px;
    }
}