.training-schedule-container {
  margin-top: 83px;
  height: 100%;
  padding: 0;
}
.training-upload-heading-bg {
  background-image: url('../images/nynfc_logo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 150px;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.training-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.training-schedule-form .form-group > input {
  border-radius: 0;
}
.training-schedule-form .form-group > input:focus {
  outline: none;
}

.line-break {
  border-top: 1px solid black;
}
.center-training-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-training-button > button {
  border: 0;
  padding: 8px 20px;
  margin-top: 1rem;
  background-color: #e5bc3b;
  cursor: pointer;
  transition: 0.3s all ease-in;
  color: black;
}
.center-training-button > button:hover {
  opacity: 0.8;
}
.d-flex input:nth-child(1) {
  margin-right: 0.2rem;
}
.training-schedule-form {
  min-width: 600px;
}
@media screen and (max-width: 550px) {
  .training-schedule-form {
    min-width: 100px;
  }
}
