.finance-page {
  background-color: rgb(0, 51, 102);
  padding-bottom: 2rem;
}
.finance-image-container {
  background-image: url('./images/finance.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  border-bottom-right-radius: 25%;
}

.finance-opacity {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 25%;
}
.finance-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}
.balance {
  text-align: center;
}
.balance > p {
  font-size: 1.2rem;
  margin-bottom: 0;
}
.balance > h2 {
  /* color: #009966; */
  font-weight: bold;
}
.income-info,
.expense-info {
  width: 300px;
  padding: 4px 10px;
  display: flex;
  justify-content: space-between;
}

.income-info > p,
.expense-info > p {
  margin-bottom: 0;
  font-weight: bold;
}

.income-info {
  background-color: rgba(0, 153, 102, 0.7);
  margin-bottom: 1rem;
}
.expense-info {
  background-color: rgba(255, 103, 1, 0.7);
}

/* Start Form */
.finance-form-fluid {
  margin-top: 4rem;
}
.finance-form-border {
  position: relative;
  padding: 15px;
  border: 1px solid white;
  max-width: 450px;
  width: 100%;
  margin: auto;
}
.finance-form-heading {
  width: 260px;
  color: white;
  font-weight: 700;
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 51, 102);
  padding: 2px 6px;
}
.finance-form-heading > h3 {
  font-size: 1.6rem;
}
.finance-form {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  max-width: 400px;
}
.finance-submit {
  display: flex;
  justify-content: center;
  align-items: center;
}
.finance-submit-btn {
  width: 150px;
  border-radius: 0;
}
.finance-form-input {
  width: 100% !important;
}
@media screen and (max-width: 700px) {
  .finance-form-fluid {
    padding: 0;
  }
  .finance-form-heading > h3 {
    font-size: 1.4rem;
  }
}
/* End Form */

/* Start Transactions */
.transaction-fluid {
  margin-top: 4rem;
  padding: 0;
  color: white;
  border-top: 2px solid white;
  padding-top: 1.2rem;
}
.transaction-container {
  margin-top: 1.2rem;
  max-width: 900px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
}
.accordion-transaction-card {
  border-radius: 0;
  margin-bottom: 0.5rem;
  color: rgb(0, 51, 102);
}
.accordion-transaction-card:hover {
  transform: scale(1);
}
.transaction-card-header {
  padding: 1px 1px 1px 5px;
}
.transaction-card-pannel > p {
  margin-bottom: 0;
  font-size: 1rem;
  overflow: hidden;
}
.transaction-income-heading {
  text-transform: uppercase;
  color: rgba(0, 153, 102, 1);
  text-align: center;
  margin-bottom: 1rem;
}
.transaction-expense-heading {
  text-transform: uppercase;
  color: rgba(255, 103, 1, 1);
  text-align: center;
  margin-bottom: 1rem;
}
@media screen and (max-width: 700px) {
  .transaction-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .transaction-income-heading {
    font-size: 1.2rem;
  }
  .transaction-expense-heading {
    font-size: 1.2rem;
  }
}
.filter-date-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.filter-input > select {
  width: 230px;
  border: 0;
  border-radius: 0;
  background-color: rgb(0, 51, 102);
  border: 1px solid white;
  color: white;
  padding: 7px 5px;
}
.filter-input > select:focus {
  outline: none;
}
.income-clr {
  color: rgba(0, 153, 102, 1);
  font-size: 0.8rem;
}
.expense-clr {
  color: rgba(255, 103, 1, 1);
  font-size: 0.8rem;
}
/* End Transactions */

/* start edit transaction form */
.edit-finance-fluid {
  padding: 0;
  background-color: rgb(0, 51, 102);
}
.finance-collapse{
  background-color: rgb(0, 51, 102);
}
/* end edit transaction form */
