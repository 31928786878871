.captain-icon{
    background-color: #ee5253;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    position: relative;
}

.captain-icon::before,
.captain-icon::after{
    content: '';
    position: absolute;
    width: 30%;
    height: 10px;
    background-color: white;
    display: block;
    top: 50%;
    transform: translateY(-50%);
}

.captain-icon::before{
    left: 0;
    
}
.captain-icon::after{
    right: 0;
}
